/*jslint white: true, nomen: true */
/*global angular */
(function () {
  "use strict";

  angular.module("app.forms").directive("formComponentGenericPassword", [
    function () {
      return {
        restrict: "E",
        templateUrl: "form-component-generic-password",
        replace: true,
        scope: {
          componentName: "=",
          model: "=",
          showError: "=?",
          defaultPlaceholder: "=?",
          isRequired: "=?",
        },
        bindToController: true,
        controllerAs: "vm",
        link: function () {},
        controller: [
          function () {
            var self = this;

            if (typeof self.isRequired === "undefined") {
              self.isRequired = false;
            }
          },
        ],
      };
    },
  ]);
})();
