/*jslint white: true, nomen: true */
/*global angular, googleApiKey, FastClick */

(function () {
  "use strict";

  angular
    .module("app", [
      "ngResource",
      "app.common",
      "app.forms",
      "app.homepage",
      "app.wherewebuild",
      "app.marketing",
      "app.hometour",
      "app.directives",
      "app.ourcollections",
      "app.customertestimonials",
      "app.buyingprocess",
      "app.buildingprocess",
      "app.virtualtours",
      "app.designoverview",
      // 'app.blog',
      "app.modeldetail",
      "app.moveindetail",
      "app.floorplandetail",
      "app.imagegallery",
      "app.lifestyle",
      "app.joinourteam",
      "app.vendorbid",
      "app.buildingabettercommunity",
      "app.discoverthedifference",
      "app.contactus",
      "app.stjudes",
      "app.longinterestlist",
      "app.energywise",
      "app.whychoosefischer",
      "app.podcast",
      "app.stlouis",
      "app.whodey",
      "app.regionRevamped",
      "app.subregionRevamped",
      "app.myfavorites",
      "app.login",
      "app.saved",
      "app.modeldetail",
      "app.moveindetail",
      "app.floorplandetail",
      "app.imagegallery",
      "app.lifestyle",
      "app.joinourteam",
      "app.vendorbid",
      "app.buildingabettercommunity",
      "app.discoverthedifference",
      "app.contactus",
      "app.stjudes",
      "app.longinterestlist",
      "app.energywise",
      "app.whychoosefischer",
      "app.podcast",
      "app.stlouis",
      "app.whodey",
      "app.floorplanfinder",
      "app.floorplanmatching",
      "ngAnimate",
      "ngRoute",
      "ngTouch",
      "ngSanitize",
      "ui.bootstrap",
      "720kb.datepicker",
      "slickCarousel",
      "angular.filter",
      "youtube-embed",
      "angular-inview",
      "modules.dataServices",
      "modules.priceStartingAt",
      "ngStorage",
      "LocalStorageModule",
      "modules.authServices",
    ])
    .config([
      "$interpolateProvider",
      function ($interpolateProvider) {
        $interpolateProvider.startSymbol("<%");
        $interpolateProvider.endSymbol("%>");
      },
    ])
    .config([
      "localStorageServiceProvider",
      function (localStorageServiceProvider) {
        localStorageServiceProvider
          .setPrefix("siteApp")
          .setStorageType("localStorage")
          .setNotify(true, true);
      },
    ])
    .config([
      "$locationProvider",
      function ($locationProvider) {
        $locationProvider.html5Mode({
          enabled: true,
          requireBase: true,
          rewriteLinks: false,
        });
      },
    ])
    .config([
      "$compileProvider",
      function ($compileProvider) {
        $compileProvider.debugInfoEnabled(false);
        $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|mailto|sms|tel):/);
      },
    ])
    .config([
      "$sceDelegateProvider",
      function ($sceDelegateProvider) {
        $sceDelegateProvider.resourceUrlWhitelist([
          "self",
          "https://open.spotify.com/**",
        ]);
      },
    ])
    .run([
      "$http",
      "$window",
      function ($http, $window) {
        /*init fastclick*/
        if ("addEventListener" in document) {
          document.addEventListener(
            "DOMContentLoaded",
            function () {
              FastClick.attach(document.body);
            },
            false
          );
        }

        /* init facebook CAPI IDs */
        $http({
          method: "GET",
          url: "/api/fb/ids",
          headers: { "Content-Type": "application/json" },
        }).success(function (data) {
          $window.localStorage.setItem("fbEventId", data.event_id);
          $window.localStorage.setItem("fbExternalId", data.external_id);
        });
      },
    ]);

  angular.module("app.common", ["ngStorage"]);
  angular.module("app.forms", ["ui.bootstrap", "ngMessages"]);
  angular.module("app.homepage", []);
  angular.module("app.wherewebuild", []);
  angular.module("app.marketing", []);
  angular.module("app.hometour", []);
  angular.module("app.directives", []);
  angular.module("app.ourcollections", []);
  angular.module("app.customertestimonials", []);
  angular.module("app.buyingprocess", []);
  angular.module("app.buildingprocess", []);
  angular.module("app.virtualtours", []);
  angular.module("app.designoverview", []);
  angular.module("app.blog", []);
  angular.module("app.modeldetail", []);
  angular.module("app.moveindetail", []);
  angular.module("app.imagegallery", []);
  angular.module("app.lifestyle", []);
  angular.module("app.joinourteam", []);
  angular.module("app.vendorbid", []);
  angular.module("app.discoverthedifference", []);
  angular.module("app.contactus", []);
  angular.module("app.buildingabettercommunity", []);
  angular.module("app.stjudes", []);
  angular.module("app.longinterestlist", []);
  angular.module("app.energywise", []);
  angular.module("app.whychoosefischer", []);
  angular.module("app.podcast", []);
  angular.module("app.stlouis", []);
  angular.module("app.whodey", []);
  angular.module("app.myfavorites", [
    "modules.snapshotRange",
    "communityCard",
    "planCard",
    "homeCard",
    "communityPlanCard",
  ]);
  angular.module("app.common", ["ngStorage"]);
  angular.module("app.forms", ["ui.bootstrap", "ngMessages"]);
  angular.module("app.homepage", []);
  angular.module("app.wherewebuild", []);
  angular.module("app.marketing", []);
  angular.module("app.hometour", []);
  angular.module("app.directives", []);
  angular.module("app.ourcollections", []);
  angular.module("app.customertestimonials", []);
  angular.module("app.buyingprocess", []);
  angular.module("app.buildingprocess", []);
  angular.module("app.virtualtours", []);
  angular.module("app.designoverview", []);
  angular.module("app.blog", []);
  angular.module("app.modeldetail", []);
  angular.module("app.moveindetail", []);
  angular.module("app.imagegallery", []);
  angular.module("app.lifestyle", []);
  angular.module("app.joinourteam", []);
  angular.module("app.vendorbid", []);
  angular.module("app.discoverthedifference", []);
  angular.module("app.contactus", []);
  angular.module("app.buildingabettercommunity", []);
  angular.module("app.stjudes", []);
  angular.module("app.longinterestlist", []);
  angular.module("app.energywise", []);
  angular.module("app.whychoosefischer", []);
  angular.module("app.podcast", []);
  angular.module("app.stlouis", []);
  angular.module("app.whodey", []);
  angular.module("app.floorplanfinder", []);
  angular.module("app.floorplanmatching", ["ngTouch"]);

  angular.module("app.login", []);
  angular.module("app.saved", []);
})();

var strTitle = function (string) {
  string = string
    .replace(/_/g, " ")
    .replace(/-/g, " ")
    .replace(/^(.)|\s+(.)/g, function ($1) {
      return $1.toUpperCase();
    });
  return string;
};

var findByKebab = function (string, list) {
  if (!string) return false;
  return list[matchKebab(string, list)];
};

var matchKebab = function (string, list) {
  for (let index = 0; index < list.length; index++) {
    if (strKebab(list[index]) == strKebab(string)) {
      return index;
    }
  }

  return -1;
};

var strKebab = function (string) {
  return string
    .replace(/([a-z])([A-Z])/g, "$1-$2")
    .replace(/'*/g, "")
    .replace(/[\s-\/\.]+/g, "-")
    .toLowerCase();
};

var strUrl = function (string) {
  return encodeURIComponent(strKebab(string));
};

// super get - safe get - sget
var sget = function (p, o) {
  return p.reduce(function (xs, x) {
    return xs && xs[x] ? xs[x] : null;
  }, o);
};

var MySavedHomes = {
  toggleSave: function (el, event) {
    const saveable_type = el.dataset.type;
    const saveable_id = el.dataset.id;
    const getClosest = function (elem, selector) {
      for (; elem && elem !== document; elem = elem.parentNode) {
        if (elem.matches(selector)) return elem;
      }
      return null;
    };
    console.log('AQUI 0', el.dataset.savedcurr);
    console.log('AQUI 1', el.dataset.saved);
    fetch("/my-favorites/save", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        saveable_type,
        saveable_id,
      }),
    })
      .then((res) => {
        console.log('AQUI 3', res);
        if (res.redirected) {
          this.requestLogin(el);
        } else {
          this.markAsSaved(el);
        }
      })
      .catch(() => {
        this.requestLogin(el);
      });

    if (el.classList.contains("remove-button")) {
      const savedItem = getClosest(event.target, ".list-view-card");
      savedItem.classList.add("remove");
    }
  },
  findSaved: function (el, event) {
    const saveable_id = el.dataset.id;
    const saveable_items = el.dataset.items;

    return saveable_items.find((item) => item === saveable_id);
  },

  requestLogin(el) {
    console.log("request-login");

    var rootElement = angular.element(
      document.querySelector('[ng-controller="loginCtrl"]')
    );
    var $rootScope = rootElement.injector().get("$rootScope");

    $rootScope.$applyAsync(function() {
        $rootScope.$broadcast("open-modal", el);
    });
  },

  markAsSaved(el) {
    console.log("mark-as-saved", el.dataset.saved, typeof el.dataset.saved);
    const beforeSaved = el.dataset.saved === 'true';
    const beforeSaved2 = el.dataset.savedcurr;
    el.dataset.saved = !beforeSaved;
    el.classList.toggle(el.dataset.class);

    if (beforeSaved) {
        var loginElement = angular.element(
            document.querySelector('[ng-controller="loginCtrl"]')
        );
        var $rootScope = loginElement.injector().get("$rootScope");
        $rootScope.$broadcast("remove-saveable", {
            type: el.dataset.type,
            saveable_id: el.dataset.id,
        });
    } else {
        // Only show modal when saving (not unsaving)
        const modal = document.getElementById('favorite-added-modal');
        modal.classList.add('show');

        // Remove modal after animation
        setTimeout(() => {
            modal.classList.remove('show');
        }, 2000);
    }
  },
};
