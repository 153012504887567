(function () {
    'use strict';
  
    angular
      .module('app.common')
      .directive('nextAvailableTour', function () {
        return {
          restrict: 'E',
          scope: {
            tourTimes: '=',
            allowToday: '=',
            tourDayOfWeek: '=?',
          },
          template: `
            <p class="type-sm next-tour">Next available tour: 
              <span class="tour-time" ng-show="tourTimesLoading"><i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i></span>
              <span class="tour-time" ng-show="!tourTimesLoading"><% nextTour %></span>
            </p>
          `,
          controller: ['$scope', 'TourService', function ($scope, TourService) {
            $scope.tourTimesLoading = true;
            const nextTourDate = TourService.calculateNextAvailableTour($scope.tourTimes, $scope.allowToday);

            $scope.$watch('allowToday', function (newValue) {
                const nextTour = TourService.calculateNextAvailableTour($scope.tourTimes, $scope.allowToday);
                $scope.nextTour = formatTourDate(nextTour);
            });
        
            $scope.$watch('tourTimes', function (newValue) {
                const nextTour = TourService.calculateNextAvailableTour($scope.tourTimes, $scope.allowToday);
                
                $scope.nextTour = formatTourDate(nextTour);
            });

            function formatTourDate(date) {
              if (!date) return null;
              $scope.tourTimesLoading = false;
  
              const now = new Date();
              const isToday = date.toDateString() === now.toDateString();
              const isTomorrow = date.toDateString() === new Date(now.getTime() + 86400000).toDateString();
  
              const timeFormatter = new Intl.DateTimeFormat('en-US', {
                hour: 'numeric',
                minute: 'numeric',
                hour12: true,
              });

              const dateFormatter = new Intl.DateTimeFormat('en-US', {
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              });

              const dayFormatter = new Intl.DateTimeFormat('en-US', {
                weekday: 'long',
              });

              if (isToday) { 
                $scope.tourDayOfWeek = 'today';
                return `Today at ${timeFormatter.format(date)}`; 
              }

              if (isTomorrow) { 
                $scope.tourDayOfWeek = 'tomorrow';
                return `Tomorrow at ${timeFormatter.format(date)}`;
              }

              $scope.tourDayOfWeek = dayFormatter.format(date);

              if(date && dateFormatter.format(date) && timeFormatter.format(date)){
                return `${dateFormatter.format(date)} at ${timeFormatter.format(date)}`;
              }

              return `<a href="">Call today</a> for a tour!`;
  
            }
  
            $scope.nextTour = formatTourDate(nextTourDate);
          }]
        };
      });
  })();
  