/*jslint white: true, nomen: true */
/*global $, angular */
(function () {
  'use strict';

  angular
    .module('app.common')
    .service('TourService', function () {

      this.daysOfWeek = [
        "sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"
      ];

      this.parseTimeStringToDate = function (timeStr, baseDate) {
        const [hours, minutes] = timeStr.split(":").map(Number);
        const date = new Date(baseDate);
        date.setHours(hours, minutes, 0, 0);
        return date;
      };

      this.formatTimeForTours = function (date) {
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const isPM = hours >= 12;
      
        const formattedHours = (hours % 12 || 12).toString().padStart(2, "0");
        const formattedMinutes = minutes.toString().padStart(2, "0");
        const period = isPM ? "pm" : "am";
      
        return `${formattedHours}:${formattedMinutes}${period}`;
      };

      this.generateHourlyTimes = function (startTime, endTime, baseDate) {
        const times = [];
        const start = this.parseTimeStringToDate(startTime, baseDate);
        const end = this.parseTimeStringToDate(endTime, baseDate);
      
        let current = new Date(start);
        while (current <= end) {
          times.push(new Date(current)); 
          current.setHours(current.getHours() + 1);
        }
        return times;
      };
      
      this.formatTimeTo12Hour = function (date) {
        const options = { hour: 'numeric', minute: 'numeric', hour12: true };
        return new Intl.DateTimeFormat('en-US', options).format(date);
      };

      this.generateWeeklyTourTimes = function (tourAvailability) {
        const result = {};
    
        this.daysOfWeek.forEach((dayName, index) => {
            const startKey = `${dayName}_start`;
            const endKey = `${dayName}_stop`;
    
            if (startKey in tourAvailability && endKey in tourAvailability) {
                const startTime = tourAvailability[startKey];
                const endTime = tourAvailability[endKey];
    
                if (startTime && endTime) {
                    const baseDate = new Date(); 
                    const offset = (index >= baseDate.getDay()) 
                        ? index - baseDate.getDay() 
                        : 7 - baseDate.getDay() + index;
    
                    baseDate.setDate(baseDate.getDate() + offset);  
                    
                    const hourlyTimes = this.generateHourlyTimes(startTime, endTime, baseDate);
                    result[index] = hourlyTimes;
                } else {
                    result[index] = [];
                }
            } else {
                result[index] = [];
            }
        });

        return result;
    };

    this.formatTourTimes = function(tourTimes) {
      function formatTime(dateString) {
        const date = new Date(dateString); 
        let hours = date.getHours();
        const minutes = date.getMinutes().toString().padStart(2, '0'); 
        const isPM = hours >= 12;

        hours = hours % 12 || 12;
        const formattedHours = hours.toString().padStart(2, '0');
    
        const period = isPM ? 'pm' : 'am'; 
    
        return `${formattedHours}:${minutes}${period}`;
      }

      const formattedTimes = {};
      for (const day in tourTimes) {
        formattedTimes[day] = tourTimes[day].map(formatTime); 
      }

      return formattedTimes;
    }

    this.calculateNextAvailableTour = function (tourTimes, allowToday) {
      if(!tourTimes){
        return null;
      }

      const now = new Date();
      const oneHourFromNow = new Date();
      oneHourFromNow.setHours(now.getHours() + 1, now.getMinutes(), 0, 0);

      const startDay = allowToday ? now.getDay() : (now.getDay() + 1) % 7;
  
      for (let offset = 0; offset < 7; offset++) {
          const dayAsInt = (startDay + offset) % 7; 
          const timesForDay = tourTimes[dayAsInt];
  
          if (!timesForDay || timesForDay.length === 0) continue;
  
          const validTimes = timesForDay.filter(time => {
            return time > oneHourFromNow;
        });
  
          if (validTimes.length > 0) {
              return new Date(validTimes[0]); 
          }
      }
      
          return null;
      };
      
      this.availableToday = function (tourAvailability) {
        const now = new Date();
        const oneHourFromNow = new Date();
        oneHourFromNow.setHours(now.getHours() + 1, now.getMinutes(), 0, 0);

        const todayName = this.daysOfWeek[now.getDay()];
        const startKey = `${todayName}_start`;
        const endKey = `${todayName}_stop`;

        if (!(startKey in tourAvailability) || !(endKey in tourAvailability)) {
          return false;
        }

        const startTime = tourAvailability[startKey];
        const endTime = tourAvailability[endKey];

        if (!startTime || !endTime) {
          return false;
        }

        const availableTimes = this.generateHourlyTimes(startTime, endTime, now);
        const validTimes = availableTimes.filter(time => time > oneHourFromNow);

        return validTimes.length > 0;
      };
    });
})();
