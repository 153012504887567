/*jslint white: true, nomen: true */
/*global angular, $ */
(function() {
  'use strict';
  angular.module('app.common')

    .controller('regionPhoneNumber', ['$scope','$timeout', 'regionStorageFactory','$window', function($scope, $timeout, regionStorageFactory, $window) {
     
      $timeout(function() {
        const firstSegment = $window.location.pathname.split('/')[1];
        $scope.region_phone = firstSegment == 'find-new-homes' && regionStorageFactory.getRegionPhone() ? regionStorageFactory.getRegionPhone() : "1-855-342-2281";
      }, 200);  

    }])

    .controller('modalCtrl', ['$scope','$rootScope', '$uibModal', 'DeviceService',
    function($scope,$rootScope, $uibModal, DeviceService) {
      $scope.open = function($elem, $size, iframe, windowClass = '') {

        if(iframe && DeviceService.check() === 'phone'){
          //for ios, user must trigger a new tab
          window.open(iframe,'_blank');
        }else{
          var uibModalInstance = $uibModal.open({
            templateUrl: $elem,
            controller: 'modalInstance',
            animation: true,
            scope: $scope,
            size: $size,
            appendTo: $('.modal-container'),
            windowClass: windowClass
          });

          $rootScope.$broadcast('modalOpen');

          uibModalInstance.result.then(null, function () {
            $rootScope.$broadcast('modalClose');
          });
        }

      };

      $scope.$on('triggerModal', function(event, args){
        $scope.open('/components/' + args.path,'compact');
      });

      $scope.prevDef = function(e){
        e.preventDefault();
      };
    }])
    .controller('modalInstance', [
      '$scope',
      '$uibModalInstance',
      'CommunitiesListDataService',
      'regionStorageFactory',
      '$window',
    function(
      $scope,
      $uibModalInstance,
      CommunitiesListDataService,
      regionStorageFactory,
      $window) {

      //cancel modal
      $scope.cancel = function() {
        $uibModalInstance.dismiss('cancel');
      };

      $scope.analyticsConfig = {
        googleAnalytics: {
          pageViewUrl: '/ajax-goals/qsu-footer-mobile'
        }
      };

    }]);
}());
