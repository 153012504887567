(function () {
  "use strict";
  angular.module("app.floorplanmatching").directive("swipeCard", function () {
    return {
      restrict: "A",
      link: function (scope, element) {
        const DECISION_THRESHOLD = 75;
        let isAnimating = false;
        let pullDeltaX = 0;

        function startDrag(event) {
          if (isAnimating) {
            return;
          }

          const actualCard = event.target.closest(".card");
          const startX = event.pageX ? event.pageX : event.touches[0].pageX;

          document.addEventListener("mousemove", onMove);
          document.addEventListener("mouseup", onEnd);

          document.addEventListener("touchmove", onMove, { passive: true });
          document.addEventListener("touchend", onEnd, { passive: true });

          function onMove(event) {
            const currentX = event.pageX ? event.pageX : event.touches[0].pageX;
            pullDeltaX = currentX - startX;

            if (pullDeltaX === 0) return;

            isAnimating = true;
            const deg = (pullDeltaX / 10) * (pullDeltaX > 0 ? 1 : -1);
            actualCard.style.transform = `translateX(${pullDeltaX}px) rotate(${deg}deg)`;
          }

          function onEnd(event) {
            document.removeEventListener("mousemove", onMove);
            document.removeEventListener("mouseup", onEnd);

            document.removeEventListener("touchmove", onMove);
            document.removeEventListener("touchend", onEnd);

            const decisionMade = Math.abs(pullDeltaX) >= DECISION_THRESHOLD;

            if (decisionMade) {
              if (pullDeltaX > 0) {
                actualCard.classList.add("swipe-right");
                actualCard.addEventListener("transitionend", function () {
                  actualCard.remove();
                });
                scope.$apply(function () {
                  scope.handleSwipeRight();
                });
              } else {
                actualCard.classList.add("reset");
                actualCard.classList.remove("swipe-left", "swipe-right");
                scope.$apply(function () {
                  scope.handleSwipeLeft();
                });
              }

              actualCard.addEventListener("transitionend", function () {
                actualCard.removeAttribute("style");
                actualCard.classList.remove("reset");

                pullDeltaX = 0;
                isAnimating = false;
              });
            }
          }
        }

        element.on("mousedown", startDrag);
        element.on("touchstart", startDrag, { passive: true });
      },
    };
  });
})();
