(function () {
  "Use Strict";
  angular.module("app.login").controller("loginCtrl", [
    "$scope",
    "$http",
    "$window",
    "AuthService",
    function ($scope, $http, $window, AuthService) {
      $scope.elementSaved = null;
      $scope.answers = null;
      $scope.saveFinishProccess = false;
      $scope.showPassword = false;
      $scope.showPasswordRegister = false;
      $scope.showPasswordConfirm = false;

      $scope.$on("open-modal", function (event, el) {
        console.log("open-modal -> call here");
        $scope.loginModal = true;
        if (el) {
          $scope.elementSaved = el;
        }
      });

      $scope.$on("send-asnwers-to-login-ctrl", function (event, answers) {
        console.log("answers", answers);
        $scope.answers = answers;
      });

      $scope.loginModal = false;
      $scope.errorMessageLogin = "";
      $scope.errorMessage = "";
      $scope.tab =
        window.location.pathname === "/create-account" ? "signUp" : "login";
      $scope.switchTab = function (tab) {
        $scope.tab = tab;
      };

      $scope.showModal = function () {
        $scope.loginModal = true;
      };
      $scope.closeModal = function () {
        $scope.loginModal = false;
      };

      $scope.registerFormData = {
        opt_in: true,
      };
      $scope.triedSubmit = false;
      $scope.checkRegisterFormValidation = function (form) {
        $scope.triedSubmit = true;
        if (!form.$valid) {
          return;
        }

        $scope.registerFormData.opt_in = Number($scope.registerFormData.opt_in);

        processRegisterForm();
      };
      function processRegisterForm() {
        $http({
          method: "POST",
          url: "/my-favorites/register/modal",
          data: $.param($scope.registerFormData), // pass in data as strings
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          }, // set the headers so angular passing info as form data (not request payload)
        })
          .success(function (data) {
            console.log(data);
            if (
              data.message === "Successfully registered account." ||
              data.message === "Successfully registered for an account!"
            ) {
              if ($window.location.pathname === "/my-favorites/login") {
                // $window.location.href = data.redirectTo;
                $window.location.href = "/account/my-favorites/welcome";
                $scope.message = data.message;
                $scope.showMessage = true;
              } else {
                $scope.loginModal = false;
                if ($scope.elementSaved) {
                  MySavedHomes.toggleSave($scope.elementSaved);
                }
                if ($scope.answers) {
                  saveAnswers($scope.answers);
                }
                if ($scope.elementSaved) {
                  $scope.showModalAfterLogin();
                }
              }
            } else {
              $scope.message = data.message;
              $scope.showMessage = true;
            }
          })
          .error(function (data, status) {
           
            if(status == 422 &&  Object.keys(data.errors).length > 0) {
              $scope.errorMessage = data.errors[Object.keys(data.errors)[0]][0];
            }else {
              $scope.errorMessage = data.message;
            }
           
          });
      }
      $scope.loginFormData = {};
      $scope.checkLoginFormValidation = function (form) {
        $scope.triedSubmit = true;
        if (!form.$valid) {
          return;
        }

        processLoginForm();
      };
      function processLoginForm() {
        $http({
          method: "POST",
          url: "/my-favorites/login",
          data: $.param($scope.loginFormData), // pass in data as strings
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          }, // set the headers so angular passing info as form data (not request payload)
        })
          .success(function (data) {
            console.log("data", data);
            console.log("$window.location", $window.location);
            if (data.status === "success") {
              $scope.errorMessage = "";
              $scope.isLoggedIn = true;
              AuthService.setLoggedInStatus(true);

              if ($window.location.pathname === "/floorplan-finder/profiles") {
                $window.location.href = "/floorplan-finder/profiles";
              } else if ($window.location.pathname === "/my-favorites/login") {
                // $window.location.href = "account/my-favorites/communities";
                $window.location.href = "/account/my-favorites/welcome";
              } else {
                // $window.location.reload();
                $scope.loginModal = false;
                if ($scope.elementSaved) {
                  MySavedHomes.toggleSave($scope.elementSaved);
                }
                if ($scope.answers) {
                  saveAnswers($scope.answers);
                }
                if ($scope.elementSaved) {
                  $scope.showModalAfterLogin();
                }
              }
              $scope.message = data.message;
              $scope.showMessage = true;
            } else {
              $scope.message = data.message;
              $scope.showMessage = true;
            }
          })
          .error(function (data, status) {
            if (
              status == 422 &&
              data.errors.email &&
              data.errors.email.length > 0
            ) {
              $scope.errorMessageLogin = data.errors.email[0];
            } else {
              $scope.errorMessageLogin = data.message;
            }
          });
      }

      $scope.showModalAfterLogin = function () {
        const modalAfterLogin = document.getElementById('after-login-modal');
        modalAfterLogin.classList.add('show');
      }

      $scope.checkMatches = function () {
        $http
          .get("/api/count-matches")
          .then(function (response) {
            if (response.data.success && response.data.count === 0) {
              window.location.href = "/floorplan-nomatch";
            } else {
              window.location.href = "/floorplan-finder/profiles";
            }
          })
          .catch(function (error) {
            window.location.href = "/floorplan-finder/preferences";
          });
      };

      $scope.handleSaveAnswers = function () {
        if ($scope.answers) {
          saveAnswers($scope.answers);
        }
      };

      function saveAnswers(answers) {
        if (!answers.selected_region || answers.answerIds.length === 0) {
          $scope.saveFinishProccess = false;
          const modalRestart = document.getElementById("modal-restart-process");
          modalRestart.style.display = "flex";
          return;
        }
        $scope.saveFinishProccess = true;
        const modalLogin = document.getElementById("loader-login");
        modalLogin.style.display = "flex";
        
        // First save answers to our system
        $http.post("/api/answers/customer", answers)
            .then(function (response) {
           
                // Then push to HubSpot
                return $http.post("/api/hubspot/questionnaire", {
                    answers: answers
                });
            })
            .then(function(hubspotResponse) {
                console.log("HubSpot response", hubspotResponse);
                $scope.checkMatches();
               
            })
            .catch(function(error) {
                console.error("Error:", error);
                // Still continue with matches even if HubSpot fails
                $scope.checkMatches();
            });
      }
    },
  ]);
})();
