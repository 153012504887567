(function () {
  "Use Strict";
  angular.module("app.myfavorites").controller("myFavoritesCtrl", [
    "$scope",
    "$http",
    "slickConfigService",
    function ($scope, $http, slickConfigService) {

      $scope.images = [
        {
            image: "/images/site/floorplan-finder/ff_hero.jpg",
            rightImg: '',
            // rightImg: '/images/site/floorplan-finder/ff-flooplan-updated.png',
            alt: "Image 1",
            title: "My Favorites",
            subtitle: "",
        },
    ]
    
    $scope.slickSettings = slickConfigService.dotsConfig(1);

    $scope.slickPrev = function ($event) {
      var nearestContainer = $event.target.closest(".reg__card-image");
      var nearestSlick = nearestContainer.querySelector(".slick-slider");

      if (nearestSlick && nearestSlick.slick) {
        nearestSlick.slick.slickPrev();
      }
    };
    $scope.slickNext = function ($event) {
      var nearestContainer = $event.target.closest(".reg__card-image");
      var nearestSlick = nearestContainer.querySelector(".slick-slider");

      if (nearestSlick && nearestSlick.slick) {
        nearestSlick.slick.slickNext();
      }
    };

      $scope.tab = "floorplans"; // possible values: "floorplans", "communities", "homes"
      $scope.switchTab = function (tab) {
        $scope.tab = tab;
      };
      $scope.dropdown = false;
      $scope.toggleDropdown = function () {
        $scope.dropdown = !$scope.dropdown;
      };
      $scope.plans = [];
      $scope.communities = [];
      $scope.homes = [];
      $scope.residences = [];

      $scope.plansSaved = [];
      $scope.communitiesSaved = [];
      $scope.homesSaved = [];
      $scope.residencesSaved = [];

      $scope.pagination = {
        floorplans: {
          currentPage: 1,
          lastPage: 1,
          pages: [],
        },
        communities: {
          currentPage: 1,
          lastPage: 1,
          pages: [],
        },
        homes: {
          currentPage: 1,
          lastPage: 1,
          pages: [],
        },
        residences: {
          currentPage: 1,
          lastPage: 1,
          pages: [],
        },
        onPageClick: function (tab, page) {
          const loadFunction = $scope.loadFunctions[tab];
          if (loadFunction) {
            loadFunction(page);
          }
        },
        onPrevClick: function (tab) {
          if ($scope.pagination[tab].currentPage > 1) {
            const loadFunction = $scope.loadFunctions[tab];
            if (loadFunction) {
              loadFunction($scope.pagination[tab].currentPage - 1);
            }
          }
        },
        onNextClick: function (tab) {
          if (
            $scope.pagination[tab].currentPage < $scope.pagination[tab].lastPage
          ) {
            const loadFunction = $scope.loadFunctions[tab];
            if (loadFunction) {
              loadFunction($scope.pagination[tab].currentPage + 1);
            }
          }
        },
      };

      $scope.getPages = function (tab) {
        const pages = Array.from(
          { length: $scope.pagination[tab].lastPage },
          function (_, i) {
            return i + 1;
          }
        );
        pages.shift();
        pages.pop();
        return pages;
      };

      $scope.setPagination = function (tab, res) {
        $scope.pagination[tab].currentPage = res.current_page;
        $scope.pagination[tab].lastPage = res.last_page;
        $scope.pagination[tab].pages = $scope.getPages(tab);
      };

      $scope.loadPlansSaved = function (page = 1) {
        $http.get("/api/plan-saved" + "?page=" + page).success(function (res) {
          if (res.success) {
            $scope.plans = res.plans;
            $scope.plansSaved = $scope.plans.map((el) => el.id);
            $scope.setPagination("floorplans", res);
          }
        });
      };

      $scope.loadHomesSaved = function (page = 1) {
        $http.get("/api/home-saved" + "?page=" + page).success(function (res) {
          if (res.success) {
            $scope.homes = res.homes;
            $scope.homesSaved = $scope.homes.map((el) => el.id);
            $scope.setPagination("homes", res);
          }
        });
      };

      $scope.loadCommunitiesSaved = function (page = 1) {
        $http
          .get("/api/community-saved" + "?page=" + page)
          .success(function (res) {
            if (res.success) {
              $scope.communities = res.communities;
              $scope.communitiesSaved = $scope.communities.map((el) => el.id);
              $scope.setPagination("communities", res);
            }
          });
      };

      $scope.loadResidencesSaved = function (page = 1) {
        $http
          .get("/api/residence-saved" + "?page=" + page)
          .success(function (res) {
            if (res.success) {
              $scope.residences = res.residences;
              const residences_plans = res.residences_plans;
              $scope.residencesSaved = $scope.residences.map((el) => el.id);
              $scope.residences = $scope.residences.map((residence) => {
                return Object.assign(residence, {
                  residence_plan: residences_plans.find(
                    ({ id }) => id === residence.plan.id
                  ),
                });
              });
              $scope.setPagination("residences", res);
              console.log("$scope.residences", $scope.residences);
            }
          });
      };

      $scope.loadFunctions = {
        floorplans: $scope.loadPlansSaved,
        communities: $scope.loadCommunitiesSaved,
        homes: $scope.loadHomesSaved,
        residences: $scope.loadResidencesSaved,
      };
      $scope.loadPlansSaved();
      $scope.loadHomesSaved();
      $scope.loadCommunitiesSaved();
      $scope.loadResidencesSaved();

      console.log("$scope.pagination", $scope.pagination);

      $scope.$on("remove-saveable", function (event, args) {
        const saveable_id = parseInt(args.saveable_id);
        if (args.type === "plan") {
          $scope.plans = $scope.plans.filter(({ id }) => id !== saveable_id);
        } else if (args.type === "community") {
          $scope.communities = $scope.communities.filter(
            ({ id }) => id !== saveable_id
          );
        } else if (args.type === "home") {
          $scope.homes = $scope.homes.filter(({ id }) => id !== saveable_id);
        }
      });
    },
  ]);
})();
