/* jslint white: true, nomen: true */
/* global angular */
(function () {
  'use strict';

  angular.module('app.forms')
    .directive('formComponentDatepicker', [function () {
      return {
        restrict: 'E',
        templateUrl: 'form-component-datepicker',
        replace: true,
        scope: {
          formattedDate: '=ngModel',
          opened: '=',
          defaultPlaceholder: '=',
          formObj: '=',
          formScope: '=',
          datepickerName: '=',
          isRequired: '=?',
          futureOnly: '=?',
          pastOnly: '=?',
          pastAndFuture: '=?',
          mode: '=?',
          includeDates: '=?',
          changeFunction: '=?',
          allowToday: "=?",
        },
        bindToController: true,
        controllerAs: 'vm',
        link: function () {},
        controller: ['$scope', '$filter', function ($scope, $filter) {
          var self = this;
          var minDate;
          var maxDate;

          if (typeof self.changeFunction === 'undefined') {
            self.changeFunction = function () {};
          }

          self.dateOptions = {
            showWeeks: false,
            dateDisabled: function (data) {
              if (data.mode !== 'day' || !self.includeDates) {
                return false;
              }

              return !_.contains(self.includeDates, data.date.getDay().toString());
            }
          };

          // Set required to true by default, but allow for conditional or false
          if (typeof self.isRequired === 'undefined') {
            self.isRequired = true;
          }

          if (typeof self.mode !== 'undefined') {
            self.dateOptions.datepickerMode = self.mode;
          }

          // Format the date selection and make it available
          $scope.$watch(function () {
            return self.dt;
          }, function (value) {
            self.formattedDate = $filter('date')(value, 'MM/dd/yy');
          });

          if (typeof self.pastOnly === 'undefined') {
            self.pastOnly = false;
          }

          if (typeof self.pastAndFuture === 'undefined') {
            self.pastAndFuture = false;
          }

          if (typeof self.futureOnly === 'undefined' && !self.pastOnly && !self.pastAndFuture) {
            self.futureOnly = true;
          }

          if (typeof self.testing === 'undefined') {
            self.testing = false;
          }

          if (self.futureOnly) {
            // Enabled future only selections
            minDate = new Date();
            maxDate = new Date();

            if(!self.allowToday){
              minDate.setDate(minDate.getDate() + 1);
            }

            maxDate.setYear(maxDate.getFullYear() + 3);
            maxDate.setDate(maxDate.getDate() + 1);

            self.dateOptions.minDate = minDate;
            self.dateOptions.maxDate = maxDate;
          }

          if (self.pastOnly) {
            // Enabled past only selections
            maxDate = new Date();
            maxDate.setDate(maxDate.getDate() + 1);

            self.dateOptions.maxDate = maxDate;
          }

          $scope.$watch(() => self.allowToday, function (newValue) {
            if(newValue){
              self.dateOptions.minDate = new Date();
            }
          });

          self.open = function (event) {
            event.preventDefault();
            event.stopPropagation();
            self.opened = true;
          };

          self.clear = function () {
            self.ngModel = null;
          };
        }]
      };
    }]);
}());
