(function () {
  "Use Strict";
  angular.module("app.login").controller("resetPasswordCtrl", [
    "$scope",
    "$http",
    "$window",
    function ($scope, $http, $window) {
      $scope.loginModal = true;
      $scope.errorMessage = "";
      $scope.tab =
        window.location.pathname === "/create-account" ? "signUp" : "login";

      $scope.registerFormData = {};
      $scope.triedSubmit = false;

      $scope.resetFormData = {};
      if (token) {
        $scope.resetFormData.token = token;
      }
      if (email) {
        $scope.resetFormData.email = email;
      }

      if (_token) {
        $scope.resetFormData._token = _token;
      }

      $scope.passwordsMatch = function () {
        return (
          $scope.resetFormData.password === $scope.resetFormData.confirmPassword
        );
      };

      $scope.checkResetFormValidation = function (form) {
        $scope.triedSubmit = true;
        if (!form.$valid && !$scope.passwordsMatch()) {
          return;
        }

        processResetPasswordForm();
      };
      function processResetPasswordForm() {
        $http({
          method: "POST",
          url: "/my-favorites/password/reset",
          data: $.param($scope.resetFormData), // pass in data as strings
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          }, // set the headers so angular passing info as form data (not request payload)
        })
          .success(function (data) {
            if (data.status === "success") {
              $scope.message = data.message;
              $scope.showMessage = true;
              $window.location.href =
                data.redirectTo || "account/my-favorites/communities";
            } else {
              $scope.message = data.message;
              $scope.showMessage = true;
            }
          })
          .error(function (data, status) {
            console.log(data);
            if (status == 422 && data.email && data.email.length > 0) {
              $scope.errorMessage = data.email[0];
            } else if (
              status == 422 &&
              data.errors.token &&
              data.errors.token.length > 0
            ) {
              $scope.errorMessage = data.errors.token[0];
            } else if (
              status == 422 &&
              data.errors.email &&
              data.errors.email.length > 0
            ) {
              $scope.errorMessage = data.errors.email[0];
            } else if (
              status == 422 &&
              data.errors.password &&
              data.errors.password.length > 0
            ) {
              $scope.errorMessage = data.errors.password[0];
            } else {
              $scope.errorMessage = data.message;
            }
          });
      }
    },
  ]);
})();
