(function () {
  "Use Strict";
  angular.module("app.myfavorites").controller("myMatchesCtrl", [
    "$scope",
    "$http",
    "slickConfigService",
    "$element",
    "regionStorageFactory",
    function ($scope, $http, slickConfigService, $element, regionStorageFactory) {

      $scope.countPlans = countPlans || 0;
      var rootElem = $element[0];
      $scope.scheduleTourModalElem = rootElem.querySelector('#scheduleTourModal');

      $scope.goToVirtualTour = function() {
        window.location.href = routeVirtualTour
      }
      $scope.scheduleVirtualTour = function() {  
          $scope.scheduleTourModalElem.showModal();
      }

      $scope.onScheduleTourCloseClick = function() {
        $scope.scheduleTourModalElem.close();
      }
      $scope.images = [
        {
            // image: "/images/site/my-favorites/my-favorites-hero-bkg.jpg",
            image: "/images/site/floorplan-finder/ff_hero.jpg",
            rightImg: '/images/site/floorplan-finder/ff-flooplan-updated.png',
            alt: "Image 1",
            title: "Your Matches",
            subtitle: "Are you ready to Fall for the Floorplan?",
            btn: {
              text: "Start Falling",
              url: routePreferences
          }
        },
    ]

    $scope.regionPhone =
        regionStorageFactory.getRegionPhone() || "1-855-342-2281";
    
    $scope.slickSettings = slickConfigService.dotsConfig(1);

    $scope.slickPrev = function ($event) {
      var nearestContainer = $event.target.closest(".reg__card-image");
      var nearestSlick = nearestContainer.querySelector(".slick-slider");

      if (nearestSlick && nearestSlick.slick) {
        nearestSlick.slick.slickPrev();
      }
    };
    $scope.slickNext = function ($event) {
      var nearestContainer = $event.target.closest(".reg__card-image");
      var nearestSlick = nearestContainer.querySelector(".slick-slider");

      if (nearestSlick && nearestSlick.slick) {
        nearestSlick.slick.slickNext();
      }
    };


      $scope.firstMaxElement = 4;
      $scope.secondMaxElement = 4;

      $scope.plans = [];
      $scope.plansDiscover = [];
      $scope.plansCloseCall = [];
      $scope.filterPLansDiscover = [];
      $scope.filterPLansCloseCall = [];
      $scope.loadingLast = false;
      $scope.loading = true;
      if (countAnswers) {
        console.log("countAnswers", countAnswers);
        $scope.finishQuestionnarie = countAnswers > 0;
      } else {
        $scope.finishQuestionnarie = false;
      }

      $scope.pagination = {
        plansDiscover: {
          currentPage: 1,
          lastPage: 1,
          pages: [],
        },
        plansCloseCall: {
          currentPage: 1,
          lastPage: 1,
          pages: [],
        },
        onPageClick: function (tab, page) {
          const is_selected = tab === "plansDiscover" ? 1 : 0;
          $scope.loadPlansMatching(is_selected, page);
        },
        onPrevClick: function (tab) {
          if ($scope.pagination[tab].currentPage > 1) {
            const is_selected = tab === "plansDiscover" ? 1 : 0;
            $scope.loadPlansMatching(
              is_selected,
              $scope.pagination[tab].currentPage - 1
            );
          }
        },
        onNextClick: function (tab) {
          if (
            $scope.pagination[tab].currentPage < $scope.pagination[tab].lastPage
          ) {
            const is_selected = tab === "plansDiscover" ? 1 : 0;
            $scope.loadPlansMatching(
              is_selected,
              $scope.pagination[tab].currentPage + 1
            );
          }
        },
      };
      $scope.getPages = function (tab) {
        const pages = Array.from(
          { length: $scope.pagination[tab].lastPage },
          function (_, i) {
            return i + 1;
          }
        );
        pages.shift();
        pages.pop();
        return pages;
      };

      $scope.setPagination = function (tab, res) {
        console.log("res", res);
        $scope.pagination[tab].currentPage = res.current_page;
        $scope.pagination[tab].lastPage = res.last_page;
        $scope.pagination[tab].pages = $scope.getPages(tab);

        console.log("pagination", $scope.pagination);
      };

      $scope.loadPlansMatching = function (is_selected = 1, page = 1) {
        return $http
          .get(
            "/api/plan-matching" +
              "?is_selected=" +
              is_selected +
              "&page=" +
              page
          )
          .success(function (res) {
            if (res.success) {
              const sortByScore = (a, b) => b.pivot.score - a.pivot.score;
              $scope.plans = res.plans.sort(sortByScore);
              if (is_selected === 1) {
                $scope.setPlans(
                  res,
                  $scope.plans,
                  "plansDiscover",
                  "filterPLansDiscover",
                  "firstMaxElement"
                );
              } else {
                $scope.setPlans(
                  res,
                  $scope.plans,
                  "plansCloseCall",
                  "filterPLansCloseCall",
                  "secondMaxElement"
                );
                // $scope.loadingLast = true;
              }
            }
          })
          .then(function (res) {
            return res;
          });
      };

      $scope.setPlans = function (
        res,
        plans,
        typePlan,
        typeFilterPlan,
        countElement
      ) {
        $scope[typePlan] = plans;
        $scope[typeFilterPlan] = $scope[typePlan].slice(
          0,
          $scope[countElement]
        );
        $scope.setPagination(typePlan, res);
      };

      $scope.initMyMatches = function () {
        Promise.all([
          $scope.loadPlansMatching(1, 1),
          $scope.loadPlansMatching(0, 1),
        ])
          .then(function (results) {
            $scope.loadingLast = true;
            $scope.loading = false;
            const loader = document.getElementById("loader");
            // loader.style.display = "none";
            loader.classList.remove("show");
            // modalRestart.style.display = "flex";
            console.log("results", results);
            console.log(" $scope.loadingLast", $scope.loadingLast);
          })
          .catch(function (error) {
            window.location.href = "/floorplan-finder/preferences";
            console.error(error);
          });
      };

      $scope.initMyMatches();

      $scope.$watch("firstMaxElement", function (maxItems) {
        $scope.filterPLansDiscover = $scope.plansDiscover.slice(0, maxItems);
      });
      $scope.$watch("secondMaxElement", function (maxItems) {
        $scope.filterPLansCloseCall = $scope.plansCloseCall.slice(0, maxItems);
      });

      $scope.$watch("loadingLast", function (loadingLast) {
        setTimeout(function () {
          if (
            $scope.loadingLast &&
            $scope.plansDiscover.length === 0 &&
            $scope.plansCloseCall.length === 0
          ) {
            
            if($scope.countPlans === 0) {
              window.location.href = "/floorplan-finder/preferences";
            } else if ($scope.finishQuestionnarie) {
              console.log("redirect a matches");
              window.location.href = "/floorplan-finder/profiles";
            } else if (!$scope.finishQuestionnarie) {
              console.log("redirect a questionnarie");
              window.location.href = "/floorplan-finder/preferences";
            }
          }
        }, 1000);
      });

      $scope.showAllElements = function (type) {
        if (type === "first") {
          $scope.firstMaxElement = $scope.plansDiscover.length;
        } else {
          $scope.secondMaxElement = $scope.plansCloseCall.length;
        }
      };

      // $scope.loadPlansMatches = function () {
      //   $http.get("/api/plan-matches").success(function (res) {
      //     if (res.success) {
      //       const sortByScore = (a, b) => b.pivot.score - a.pivot.score;
      //       $scope.plans = res.plans.sort(sortByScore);
      //       // $scope.plansDiscover = $scope.plans.slice(0, 10);
      //       // $scope.plansCloseCall = $scope.plans.slice(10, 20);
      //       $scope.plansDiscover = $scope.plans.filter(
      //         (el) => el.pivot.is_selected === 1
      //       );
      //       console.log("$scope.plansDiscover", $scope.plansDiscover);
      //       $scope.plansCloseCall = $scope.plans.filter(
      //         (el) => el.pivot.is_selected === 0
      //       );
      //       console.log("$scope.plansCloseCall", $scope.plansCloseCall);
      //       $scope.filterPLansDiscover = $scope.plansDiscover.slice(
      //         0,
      //         $scope.firstMaxElement
      //       );
      //       $scope.filterPLansCloseCall = $scope.plansCloseCall.slice(
      //         0,
      //         $scope.secondMaxElement
      //       );
      //     }
      //   });
      // };
    },
  ]);
})();
