(function () {
  "use strict";
  angular.module("app.common").service("slickConfigService", [
    "$window",
    "$timeout",
    function ($window, $timeout) {
      this.modalSlideshow = function ($scope) {
        $scope.slickLarge = {
          arrows: false,
          infinite: true,
          method: {},
        };
      };

      this.dualSlideshow = function ($scope, thumbCount) {
        $scope.slickMain = {
          arrows: true,
          infinite: true,
          asNavFor: "#slick-thumb slick",
          method: {},
        };

        $scope.slickThumb = {
          arrows: false,
          infinite: true,
          slidesToShow: thumbCount,
          slidesToScroll: 1,
          focusOnSelect: true,
          method: {},
          asNavFor: "#slick-main slick",
        };

        //slick link out if contains link
        $scope.linkOut = function (url) {
          if (url) {
            $window.open(url, "_blank");
          }
        };

        $scope.slickThumbSlide = function (dir) {
          $("#slick-main").add(dir).click();
        };
      };

      this.triggerClick = function ($scope, $target) {
        var isTrigger = false;

        $scope.dragLinkDetect = function () {
          console.log("is being dragged");
          isTrigger = true;
          $timeout(function () {
            isTrigger = false;
          }, 200);
        };

        $scope.triggerLink = function (url) {
          if (isTrigger) {
            $window.open(url, $target);
          }
        };
      };

      this.centerBaseCfg = function (num, $scope) {
        return {
          arrows: false,
          centerMode: true,
          infinite: false,
          slidesToShow: num,
          slidesToScroll: 1,
          focusOnSelect: true,
          method: {},
          event: {
            beforeChange: function (event, slick, currentSlide, nextSlide) {
              if ($scope) {
                $scope.activeSlide = nextSlide;
              }
            },
          },
        };
      };

      this.baseConfig = function (num) {
        return {
          arrows: false,
          infinite: true,
          slidesToShow: num,
          slidesToScroll: num,
          focusOnSelect: true,
          enabled: true,
          method: {},
        };
      };

      this.fadeConfig = function (num) {
        return {
          arrows: false,
          infinite: true,
          slidesToShow: num,
          slidesToScroll: num,
          focusOnSelect: true,
          enabled: true,
          fade: true,
          cssEase: "linear",
          method: {},
        };
      };

      this.dotsConfig = function (num) {
        return {
          arrows: false,
          dots: true,
          infinite: true,
          slidesToShow: num,
          slidesToScroll: num,
          focusOnSelect: true,
          method: {},
        };
      };

      this.dotsConfigInitialSlide = function (num, initSlide) {
        return {
          arrows: false,
          dots: true,
          infinite: true,
          slidesToShow: num,
          slidesToScroll: num,
          focusOnSelect: true,
          initialSlide: initSlide,
          method: {},
        };
      };
    },
  ]);
})();
