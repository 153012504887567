/*jslint white: true, nomen: true */
/*global angular */
(function () {
  "use strict";

  angular.module("app.microsite").controller("MicrositeContactCtrl", [
    "$scope",
    "$timeout",
    "$window",
    "DataService",
    function ($scope, $timeout, $window, DataService) {
      // Function to update placeholders based on label text
      function updateFormPlaceholders() {
        // Select the form by its ID
        const form = document.getElementById(
          "hsForm_099207ec-eaf5-46c3-bac7-db753a3811f9"
        );

        if (form) {
          // Find all label elements within the form
          const labels = form.getElementsByTagName("label");

          // Loop through each label
          Array.from(labels).forEach(function (label) {
            // Get the 'for' attribute which corresponds to the input ID
            var inputId = label.getAttribute("for");

            // Find the input element that matches the label
            var input = form.querySelector("#" + inputId);

            if (input) {
              // Set the input's placeholder attribute to the label's text
              input.placeholder = label.textContent.trim();
            }
          });
        }
      }
      /*
        $scope.map = {
          center: communityCenter,
          control: {},
          markers: [],
          zoom: 15,
          windows: [],
          markersEvents: {
            click: function(marker, eventName, model, args) {
              $scope.map.windows[model.id].show = true;
            }
          }
        };
*/
      $scope.communityPromise = DataService.getData(
        $window.location.pathname,
        "community"
      ).promise.then(function (community) {
        $scope.community = community;
        $timeout(function () {
          updateFormPlaceholders();
        }, 0);

      });

      $scope.micrositeContactAnalyticsConfig = {
        googleAnalytics: {
          pageViewUrl: "/ajax-goals/microsite-contact",
        },
      };
    },
  ]);
})();
