angular.module('app').controller('salesPriorityOneCtrl', ['$scope', '$location', 'dummyDataService', 'TourService', 'slickConfigService', function($scope, $location, dummyDataService, TourService, slickConfigService) {

    $scope.tourAvailability = dummyDataService.daytonTourTimes();
    $scope.allowToday = TourService.availableToday($scope.tourAvailability);
    $scope.tourTimes = TourService.generateWeeklyTourTimes($scope.tourAvailability);
    $scope.formattedTourTimes = TourService.formatTourTimes($scope.tourTimes);


    // Getting all models for the region so we can dynamically display the utour link based on the UTM data. 
    // Separating out models with sales office hours to display in the showcase section.
    $scope.modelHomes = [];
    $scope.showcaseHomes = [];

    if (window.SalesPriorityData) {
        $scope.region = window.SalesPriorityData.region;
        $scope.modelHomes = window.SalesPriorityData.modelHomes;
        $scope.modelHomes.forEach(modelHome => {
            if(modelHome.sales_office_hours){
                $scope.showcaseHomes.push(modelHome);
            }
        });
    } else {
        console.error("SP1 Data not found!");
    }

    $scope.showcaseSlickSettings = slickConfigService.baseConfig(1);

    // This serves as our general utour URL if no virtual_tour_link is found for the community's model.
    var utourGuestUrl = ' https://fischer.utourhomes.com/visitor?division=93aeac7b-178b-418d-b674-b3daf4dc638d&rootUrl=division&lang=en';

    $scope.utmContent = $location.search().utm_content || '';
    $scope.communityName = $scope.utmContent.replace(/-/g, ' ').toUpperCase();
    $scope.utourUrl = '';

    $scope.modelHomes.forEach(modelHome => {
        modelHome.communityNameLower = modelHome.formattedCommunity.toLowerCase();
        if(modelHome.formattedCommunity == $scope.communityName){
            $scope.utourUrl = modelHome.utour_url ? modelHome.utour_url : utourGuestUrl;
            $scope.tourLocation = modelHome.formattedAddress 
             ? {
                address: modelHome.address,
                city: modelHome.city,
                state: modelHome.state,
                zip: modelHome.zip,
             }
             : '';
        }
    });

    if(!$scope.utourUrl){
        $scope.utourUrl = utourGuestUrl;
    }

    $scope.tourDayOfWeek = '';

}]);