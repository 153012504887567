/*jslint white: true, nomen: true */
/*global angular */
(function () {
    'use strict';

    angular.module('app.microsite').controller('MicrositeLocationCtrl', [
        '$scope',
        function ($scope) {

            $scope.micrositeContactAnalyticsConfig = {
                googleAnalytics: {
                    pageViewUrl: '/ajax-goals/microsite-contact',
                },
            };

            Promise.all([
                google.maps.importLibrary('marker'),
                google.maps.importLibrary('maps'),
            ]).then(function () {

                var pageMap = document.getElementById('pageMap');
                var lat = communityCenter ? communityCenter.latitude : 0;
                var lng = communityCenter ? communityCenter.longitude : 0;

                lat = isNaN(lat) ? 0 : parseFloat(lat);
                lng = isNaN(lng) ? 0 : parseFloat(lng);

                if (pageMap) {
                    var map = new google.maps.Map(pageMap, {
                        mapId: 'communityMap',
                        center: { lat: lat, lng: lng },
                        zoom: 15,
                        options: {
                            mapTypeControl: false,
                            streetViewControl: false,
                            fullscreenControl: false,
                        },
                    });

                    var markerContent = document.createElement('div');

                    markerContent.classList.add('map-pin');
                    markerContent.innerHTML = '<img alt="Community Center" src="/images/site/microsite/map-pin-contact.png" width="30" height="30" />';

                    var marker = new google.maps.marker.AdvancedMarkerElement({
                        position: { lat: lat, lng: lng },
                        map: map,
                        title: 'Community Center',
                        content: markerContent,
                    });
                }
            });
        },
    ]);
})();
