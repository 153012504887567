(function () {
  "Use Strict";
  angular.module("homeCard", []).component("homeCard", {
    templateUrl: "/components/cards/home-card",
    bindings: {
      home: "<",
      homesSaved: "<",
    },
    controller: function () {
      var vm = this;

      vm.slickPrev = function ($event) {
        var nearestContainer = $event.target.closest(".reg__card-image");
        var nearestSlick = nearestContainer.querySelector(".slick-slider");

        if (nearestSlick && nearestSlick.slick) {
          nearestSlick.slick.slickPrev();
        }
      };
      vm.slickNext = function ($event) {
        var nearestContainer = $event.target.closest(".reg__card-image");
        var nearestSlick = nearestContainer.querySelector(".slick-slider");

        if (nearestSlick && nearestSlick.slick) {
          nearestSlick.slick.slickNext();
        }
      };
    },
  });
})();
