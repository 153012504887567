/*jslint white: true, nomen: true */
/*global angular, $, communityId */
(function () {
  "use strict";

  angular.module("app.microsite").controller("MicrositeFloorplanDetailsCtrl", [
    "$scope",
    "$window",
    "DataService",
    "slickConfigService",
    "mortageCalculatorService",
    "DeviceService",
    "$timeout",
    function (
      $scope,
      $window,
      DataService,
      slickConfigService,
      mortageCalculatorService,
      DeviceService,
      $timeout
    ) {
      $scope.residence = null;

      slickConfigService.triggerClick($scope, "_self");
      $scope.slickFloorplan = slickConfigService.baseConfig(1);
      $scope.modalSlide = 0;
      slickConfigService.modalSlideshow($scope);
      $scope.triggerSlick = false;
      $scope.planLoaded = false;
      $scope.images;
      $scope.showZoom = true;
      var loadedOnce = false;

      $(window).on("load resize", function () {
        $scope.imagesLoaded = false;

        var thumbCount = 5;
        var stripCount = 2;

        if (DeviceService.check() == "iPad") {
          thumbCount = 3;
          if (DeviceService.orientation() == "portrait") {
            thumbCount = 2;
            stripCount = 1;
          }
        }
        if (DeviceService.check() == "phone") {
          thumbCount = Math.round($(window).width() * 0.01) - 1;
          stripCount = 1;
        }

        $scope.slickStrip = slickConfigService.baseConfig(stripCount);
        slickConfigService.dualSlideshow($scope, thumbCount);
        $timeout(function () {
          /*
            if images are loading for the first time, allow floorplanPromise to to init the slideshow or
            else assume the slides are ready and init the slideshow after calculating thumbsize
            */
          if (loadedOnce) {
            $scope.imagesLoaded = true;
          }
        }, 300);
      });

      $scope.disclaimer = disclaimer;

      $scope.communityPromise = DataService.getData($window.location.pathname)
        .promise.then(function (result) {
          $scope.community = result.community;
          $scope.residence = result.residence;

          $scope.residence.payment = calculatePayment($scope.residence.price);

          angular.forEach(
            $scope.residence.plan.elevationImages,
            function (elevationImage) {
              elevationImage.image = elevationImage.img;
              delete elevationImage.img;
            }
          );

          $scope.residence.plan.beds =
            $scope.residence.plan.bed_low +
            "-" +
            $scope.residence.plan.bed_high;
          $scope.residence.plan.baths =
            $scope.residence.plan.bath_low +
            "-" +
            $scope.residence.plan.bath_high;
          $scope.residence.plan.sqft =
            $scope.residence.plan.sqft_low +
            "-" +
            $scope.residence.plan.sqft_high;

          $scope.images = $scope.residence.plan.videos.concat(
            $scope.residence.plan.elevationImages.concat(
              $scope.residence.plan.assets
            )
          );

          mortageCalculatorService.setPrice($scope.residence.price);

          // API can come back with modelHomes or model_homes
          if ($scope.residence.plan.model_homes === undefined) {
            $scope.residence.plan.model_homes =
              $scope.residence.plan.modelHomes;
          }

          angular.forEach(
            $scope.residence.plan.model_homes,
            function (model_home) {
              model_home.elevation = $scope.residence.plan.elevationImages[0];
              $scope.imagesLoaded = true;
              loadedOnce = true;
            }
          );

          $scope.planLoaded = true;
        })
        .then(function () {
          $scope.imagesLoaded = true;
          loadedOnce = true;
        });

      $scope.setSlide = function (num) {
        $scope.modalSlide = num;
        $scope.slickMain.method.slickGoTo(num);
      };

      var calculatePayment = function (price) {
        var pmiPayment = 0,
          propertyTaxPayment = 0,
          homeInsurancePayment = 0;

        var principle = price - price * (down_payment / 100);

        var payments = 30 * 12;
        var monthlyInterestRate =
          (parseFloat(interest_rate) + parseFloat(interest_rate_adjustment)) /
          100 /
          12;
        var basePayment =
          (principle *
            monthlyInterestRate *
            Math.pow(1 + monthlyInterestRate, payments)) /
          (Math.pow(1 + monthlyInterestRate, payments) - 1);

        if (pmi && pmi > 0) {
          pmiPayment = principle * (pmi / 100 / 12);
        }

        if (property_tax && property_tax > 0) {
          propertyTaxPayment = price * (property_tax / 100 / 12);
        }

        if (home_insurance && home_insurance > 0) {
          homeInsurancePayment = price * (home_insurance / 100 / 12);
        }

        return (
          basePayment + pmiPayment + propertyTaxPayment + homeInsurancePayment
        );
      };
    },
  ]);
})();
