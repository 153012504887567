/*jslint white: true, nomen: true */
/*global angular, $, communityId */
(function () {
  "use strict";
  angular.module("app.floorplanfinder").controller("floorplanFinderCtrl", [
    "$scope",
    "$rootScope",
    "slickConfigService",
    "DataService",
    "$timeout",
    "regionStorageFactory",
    function ($scope, $rootScope, slickConfigService, DataService, $timeout, regionStorageFactory) {
      $scope.regionPhone = regionStorageFactory.getRegionPhone();

				if(!$scope.regionPhone){
					$scope.regionPhone = '855-342-2281';
				}
      $scope.selected_region = null;
      $scope.questions = null;
      $scope.questionsSuccess = false;
      $scope.selectedCollectionId = null;
      $scope.answers = {
        selected_region: null,
        answerIds: [],
      };
      $scope.filteredAnswersByQuestionPropertyType = [];
      $scope.filteredAnswersByQuestionHomeType = [];
      $scope.filteredAnswersByQuestionCollectionId = [];

      $scope.userStatus = {
        loggedIn: null,
        questionnaireCompleted: null,
      };

      $scope.selected_region = null;
      $scope.questions = null;
      $scope.questionsSuccess = false;
      $scope.answers = {
        selected_region: null,
        answerIds: [],
      };

      $scope.buttonStates = {};

      $scope.loading = false;
      $scope.success = false;

      function showLoading() {
        $timeout(function () {
          $scope.loading = false;
          $scope.success = true;
        }, 3000);
      }

      function fetchUserStatus() {
        DataService.getData("/api/check-session-and-questionnaire-status")
          .promise.then(function (response) {
            if (response.data) {
              $scope.userStatus.loggedIn = true;
              $scope.userStatus.questionnaireCompleted =
                response.data.success || false;
            } else {
              console.error("Unexpected response structure:", response);
              $scope.userStatus.loggedIn = false;
              $scope.userStatus.questionnaireCompleted = false;
            }
          })
          .catch(function (error) {
            if (error.status === 401) {
              console.warn("User is not logged in (401 Unauthorized).");
              $scope.userStatus.loggedIn = false;
            } else {
              console.error("Error fetching user status:", error);
            }
          });
      }

      fetchUserStatus();

      DataService.getData("/api/questions", "questions")
        .promise.then(function (response) {
          $scope.questions = response;
          $scope.questions.sort((a, b) => a.display_order - b.display_order);

          const regionQuestionIndex = $scope.questions.findIndex(
            (q) => q.related_field === "region"
          );

          if ($scope.questions && $scope.questions[regionQuestionIndex]) {
            $scope.questions[regionQuestionIndex] = Object.assign(
              $scope.questions[regionQuestionIndex],
              {
                regions: $scope.questions[regionQuestionIndex].answers
                  .map((el) => ({
                    id: Number(el.answer_value),
                    name: el.display_text,
                  }))
                  .sort((a, b) => a.name.localeCompare(b.name)),
              }
            );
          }

          $scope.loginQuestion = {
            id: 99,
            display_text: "Almost There",
            type: "login",
          };

          $scope.questions.push($scope.loginQuestion);

          $scope.questions.forEach((question) => {
            if (question.id !== undefined) {
              $scope.buttonStates[question.id] = false;
            }
          });

          $timeout(function () {
            const baseConfig = slickConfigService.baseConfig(1);

            $scope.slickSettings = angular.extend(baseConfig, {
              dots: false,
              infinite: false,
              adaptiveHeight: true,
              swipe: false,
              draggable: false,
              touchMove: false,
            });

            $scope.questionsSuccess = true;
          });
        })
        .catch(function (error) {
          console.error("Error fetching questions:", error);
        });

      $scope.nextQuestion = function ($event) {
        const slickElement = $event.target.closest(".slick-initialized");

        if (slickElement) {
          $(slickElement).slick("slickNext");
          var slickInstance = $(slickElement).slick("getSlick");
          $scope.activeQuestion = slickInstance.currentSlide;
          if (slickInstance.currentSlide === $scope.questions.length - 1) {
            $scope.loading = true;
            $rootScope.$broadcast("send-asnwers-to-login-ctrl", $scope.answers);
            showLoading();
          }
        } else {
          console.error("Cannot find questions slider.");
        }
      };

      $scope.selectRegion = function (regionId, event) {
        const regionQuestion = $scope.questions.find(
          (q) => q.related_field === "region"
        );
        const selectedRegion = regionQuestion
          ? regionQuestion.regions.find((region) => region.id === regionId)
          : null;

        if (selectedRegion) {
          $scope.selected_region = selectedRegion;
          $scope.answers["selected_region"] = selectedRegion.id;

          const dropdownController = angular
            .element(event.target)
            .controller("uibDropdown");
          if (dropdownController) {
            dropdownController.toggle(false);
          } else {
            console.warn("Dropdown controller not found.");
          }

          $scope.buttonStates[0] = true;
          return true;
        }

        console.error(`Invalid region selected with ID: ${regionId}`);
        return false;
      };

      $scope.selectAnswer = function (questionId, answerId) {
        if (validAnswer(questionId, answerId)) {
          recordAnswer(questionId, answerId);
        } else {
          console.error(
            `Invalid answer selected for question ID: ${questionId}`
          );
        }

        function validAnswer(questionId, answerId) {
          const question = $scope.questions.find((q) => q.id === questionId);

          if (!question) {
            console.error(`Question not found with ID: ${questionId}`);
            return false;
          }

          return question.answers.some((answer) => answer.id === answerId);
        }

        function recordAnswer(questionId, answerId) {
          const existingAnswerIndex = $scope.answers.answerIds.findIndex(
            (existingAnswerId) => {
              const existingQuestion = $scope.questions.find(
                (q) =>
                  Array.isArray(q.answers) &&
                  q.answers.some((a) => a.id === existingAnswerId)
              );
              return existingQuestion && existingQuestion.id === questionId;
            }
          );

          $scope.buttonStates[questionId] = true;

          const currentQuestion = $scope.questions.find(
            (el) => el.id === questionId
          );
          //   console.log("currentQuestion", currentQuestion);
          if (
            currentQuestion &&
            currentQuestion.related_field === "collection_id"
          ) {
            $scope.selectedCollectionId = answerId;
          }

          if (existingAnswerIndex !== -1) {
            $scope.answers.answerIds[existingAnswerIndex] = answerId;
          } else {
            $scope.answers.answerIds.push(answerId);
          }
        }
      };

      $scope.$watch(
        "answers.selected_region",
        function (selectedRegion, oldVal) {
          if ($scope.questions && $scope.questions.length > 0) {
            const questionCollectionId = $scope.questions.find(
              (q) => q.related_field === "collection_id"
            );
            $scope.filteredAnswersByQuestionCollectionId =
              questionCollectionId.answers.filter(
                (el) => el.region_id === selectedRegion
              );
          }
        }
      );

      // $scope.$watch("activeQuestion", function (indexQuestion, oldVal) {
      
      //   if (typeof indexQuestion === "undefined") {
      //     return;
      //   }
      //   const currentQuestion = $scope.questions[indexQuestion];
       
      //   if (currentQuestion && currentQuestion.related_field === "home_type") {
      //     const filterCollection = $scope.questions.find(
      //       (q) => q.related_field === "collection_id"
      //     );
       
      //     const collection = filterCollection.answers.find(
      //       (el) => el.id === $scope.selectedCollectionId
      //     );
      //     const collectionId = Number(collection.answer_value);
      //     $scope.filteredAnswersByQuestionHomeType =
      //       currentQuestion.answers.filter(
      //         (el) => el.collection_id === collectionId
      //       );
      //   }
      // });
    },
  ]);
  // console.log('submitForm called', $scope.answers);
  // DataService.postData('/api/answers/customer', $scope.answers, 'message')
  // .promise
  // .then(function(response) {
  //     console.log('post data response', response);
  // }).catch(function(error){
  //     console.warn(error);
  // })
})();
