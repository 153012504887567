/*global angular, $, communityId */
(function () {
  "use strict";
  angular.module("app.floorplanmatching").controller("floorplanMatchingCtrl", [
    "$scope",
    "$rootScope",
    "slickConfigService",
    "DataService",
    "$timeout",
    "$http",

    function (
      $scope,
      $rootScope,
      slickConfigService,
      DataService,
      $timeout,
      $http
    ) {

      console.log("user", user.email)
      $scope.initialSlideIndex = 3;

      const anwers = user.answers.map((answer) => {
          return {
            question: answer.question.display_text,
            weight : answer.question.weight,
            anwer: answer.display_text,
          }
        });

      console.log("answers", anwers);
      $scope.plans = null;
      $scope.plan = null;
      $scope.filterAssets = null;
      $scope.selectedPlan = 0;
      $scope.transition = false;
      $scope.left = false;
      $scope.right = false;
      $scope.loading = true;
      $scope.show = {
        like: false,
        dislike: false,
      };
      $scope.minScoreField = 0;
      if (minScoreField) $scope.minScoreField = minScoreField;
      
      $scope.minScoreModalField = 0;
      if (minScoreModalField) $scope.minScoreModalField = minScoreModalField;

      $scope.selectedAsset = null;
      $scope.assetModal = false;
      $scope.matchModal = false;
      $scope.resetMatches = false;
      $scope.modal = {
        match: false,
        init: false,
      };
      $scope.finish = false;
      $scope.isHandleSwipe = false;

      $scope.slickSettings = slickConfigService.dotsConfig(1);
      $scope.slickSettingsWithInitial = slickConfigService.dotsConfigInitialSlide(1, 0);

      $scope.slickPrev = function ($event) {
        var nearestContainer = $event.target.closest(".reg__card-image");
        var nearestSlick = nearestContainer.querySelector(".slick-slider");

        if (nearestSlick && nearestSlick.slick) {
          nearestSlick.slick.slickPrev();
        }
      };
      $scope.slickNext = function ($event) {
        var nearestContainer = $event.target.closest(".reg__card-image");
        var nearestSlick = nearestContainer.querySelector(".slick-slider");

        if (nearestSlick && nearestSlick.slick) {
          nearestSlick.slick.slickNext();
        }
      };

      console.log("plans", plans.map((plan) => {
        return {
          id: plan.id,
          name: plan.name,
          score: plan.score, 
          scoresAnswer: plan.scoresAnswer.map((answer) => {
            return {
              answer_id: answer.id,
              answer: answer.display_text,
              customer_id: answer.pivot.customer_id,
              plan_id: answer.pivot.plan_id,
              score: answer.pivot.score,
            }
          }),
        }
      }));

      if (plans) {
        $scope.plans = plans;

        $scope.plan = $scope.plans[$scope.selectedPlan];
        if (
          $scope.plans[$scope.selectedPlan] &&
          $scope.plans[$scope.selectedPlan].assets
        ) {
          $scope.filterAssets = $scope.plans[$scope.selectedPlan].assets.slice(
            0,
            8
          );
        }
        if (
          $scope.plans[$scope.selectedPlan] &&
          $scope.plans[$scope.selectedPlan].subtitle &&
          $scope.plans[$scope.selectedPlan].subtitle.length > 0
        ) {
          $scope.arraySubtitle =
            $scope.plans[$scope.selectedPlan].subtitle.split("|");
        }
        $scope.loading = false;
        if ($scope.plans.length === 0) {
          $scope.finish = true;
        }
      }

      $scope.handleLoveIt = function () {
        saveMatch(1);
        $scope.isHandleSwipe = true;
        $timeout(function () {
          if ($scope.plans[$scope.selectedPlan].score > $scope.minScoreModalField) {
            $scope.modal.match = true;
          }
        }, 1000);
      };

      $scope.handleNotForMe = function () {
        $scope.isHandleSwipe = true;
        saveMatch(0);
      };

      $scope.openModalAsset = function (asset, index) {
        console.log("asset: index", index);
        $scope.initialSlideIndex = index;
        $scope.slickSettingsWithInitial = slickConfigService.dotsConfigInitialSlide(1, index );
        $scope.selectedAsset = asset;
        $scope.assetModal = true;
      };

      $scope.closeModalAsset = function () {
        $scope.selectedAsset = null;
        $scope.assetModal = false;
      };

      $scope.openModal = function (option) {
        $scope.modal[option] = true;
      };
      $scope.closeModal = function (option) {
        $scope.modal[option] = false;
        if (option === "init") {
          setCookie("modalInstructions", "true", 50);
        }
      };

      $scope.viewDetails = function (plan) {
        window.location.href = plan.url;
      };

      $scope.scrollToHero = function () {
        const element = document.getElementById("hero");
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      };

      $scope.$watch("selectedPlan", function (selected) {
        if ($scope.plans && $scope.plans.length > 0) {
          $scope.filterAssets = $scope.plans[selected].assets.slice(0, 8);
          console.log("filterAssets",  $scope.filterAssets );
          $scope.plan = $scope.plans[selected];
          if (
            $scope.plans[selected] &&
            $scope.plans[selected].subtitle &&
            $scope.plans[selected].subtitle.length > 0
          ) {
            $scope.arraySubtitle = $scope.plans[selected].subtitle.split("|");
          }
        }
      });

      $scope.handleSwipeRight = function () {
        console.log("Swiped right");
        $scope.show.like = true;
        $scope.right = true;
        $scope.left = false;

        $scope.handleLoveIt();
      };

      $scope.handleSwipeLeft = function () {
        console.log("Swiped left");
        $scope.show.dislike = true;
        $scope.right = false;
        $scope.left = true;

        $scope.handleNotForMe();
      };

      $scope.goMatches = function () {
        window.location.href = "/account/my-matches";
      };

      $scope.removeMatches = function () {
        console.log("removeMatches");
        $scope.resetMatches = true;
        $http
          .post("/api/update-plan-match-empty")
          .then(function (response) {
            if (response.data.success) {
              window.location.reload();
            }
          })
          .catch(function (error) {
            $rootScope.$broadcast("open-modal");
          });
      };

      function setCookie(name, value, days) {
        var expires = "";
        if (days) {
          var date = new Date();
          date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
          expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "") + expires + "; path=/";
      }

      function getCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(";");
        for (var i = 0; i < ca.length; i++) {
          var c = ca[i];
          while (c.charAt(0) == " ") c = c.substring(1, c.length);
          if (c.indexOf(nameEQ) == 0)
            return c.substring(nameEQ.length, c.length);
        }
        return null;
      }

      function saveMatch(isSelected) {
        const payload = {
          plan_id: $scope.plan.id,
          is_selected: isSelected,
        };
        $http
          .post("/api/plan-match", payload)
          .then(function (response) {
            if (response.data.success) {
              if ($scope.plans.length === $scope.selectedPlan + 1) {
                $scope.finish = true;
                // window.location.href = "/my-favorites/my-matches";
              } else {
                $timeout(function () {
                  $scope.selectedPlan++;
                }, 700);
              }
              $timeout(function () {
                $scope.right = false;
                $scope.left = false;
                $scope.show.like = false;
                $scope.show.dislike = false;
                $scope.isHandleSwipe = false;
              }, 1000);
            }
          })
          .catch(function (error) {
            $rootScope.$broadcast("open-modal");
          });
      }

      const DECISION_THRESHOLD = 120;
      let isAnimating = false;
      let pullDeltaX = 0;
      let cardFloorplan;
      let maxWidth = window.innerWidth;

      // if (maxWidth < 992) {
      const modalInstructions = getCookie("modalInstructions");
      if (!modalInstructions) {
        $scope.modal.init = true;
      }

      setTimeout(() => {
        cardFloorplan = document.getElementById("floorplan");
        maxWidth = window.innerWidth;
      }, 1000);

      function startDrag(event) {
        if ($scope.finish) return;
        if (maxWidth > 992) return;
        if (isAnimating) return;

        // const actualCard = event.target.closest(".card-image");
        const actualCard = event.target.closest("#floorplan");

        // console.log(event);

        const startX = event.pageX ? event.pageX : event.touches[0].pageX;

        document.addEventListener("mousemove", onMove);
        document.addEventListener("mouseup", onEnd);

        document.addEventListener("touchmove", onMove, { passive: true });
        document.addEventListener("touchend", onEnd, { passive: true });

        function onMove(event) {
          const currentX = event.pageX ? event.pageX : event.touches[0].pageX;

          pullDeltaX = currentX - startX;

          if (pullDeltaX === 0) return;

          isAnimating = true;

          const deg = pullDeltaX / 10;
          // actualCard.style.transform = `translateX(${pullDeltaX}px) rotate(${deg}deg)`;

          cardFloorplan.style.transform = `translateX(${pullDeltaX}px)`;
        }

        function onEnd(event) {
          document.removeEventListener("mousemove", onMove);
          document.removeEventListener("mouseup", onEnd);

          document.removeEventListener("touchmove", onMove);
          document.removeEventListener("touchend", onEnd);

          const decisionMade = Math.abs(pullDeltaX) >= DECISION_THRESHOLD;

          if (decisionMade) {
            console.log(" decisionMade");

            const goRight = pullDeltaX > 0;
            if (goRight) {
              cardFloorplan.classList.add("swipe-right");

              $scope.show.like = true;
              $scope.handleLoveIt();
            } else {
              console.log("left");
              cardFloorplan.classList.add("swipe-left");
              $scope.show.dislike = true;
              $scope.handleNotForMe();
            }
          } else {
            console.log("no decisionMade");

            cardFloorplan.classList.remove("swipe-left", "swipe-right");
            cardFloorplan.removeAttribute("style");
            cardFloorplan.classList.remove("reset");
            isAnimating = false;
            pullDeltaX = 0;
          }

          setTimeout(() => {
            pullDeltaX = 0;
            isAnimating = false;
          }, 100);
          setTimeout(() => {
            cardFloorplan.removeAttribute("style");
            cardFloorplan.classList.remove("reset");
            cardFloorplan.classList.remove("swipe-left", "swipe-right");
          }, 1000);
        }
      }

      document.addEventListener("mousedown", startDrag);
      document.addEventListener("touchstart", startDrag, { passive: true });
    },
  ]);
})();
