(function () {
  "Use Strict";
  angular.module("app.myfavorites").controller("sidebarCtrl", [
    "$scope",
    "$http",
    function ($scope, $http) {
      $scope.activeSidebar = window.location.pathname;
      $scope.isSidebarOpen = false;
      $scope.openSidebar = function () {
        $scope.isSidebarOpen = true;
      };
      $scope.closeSidebar = function () {
        $scope.isSidebarOpen = false;
      };

      $scope.goPreferences = function () {
        window.location.href = "/floorplan-finder/preferences";
      };

      $http.get("/my-favorites/my-profile/edit").then(function (response) {
        $scope.customer = response.data.user;
      });

      // $http
      //   .get("/api/check-session-and-questionnaire-status")
      //   .then(function (response) {
      //     console.log("check session and questionnarie", response.data);
      //     //   {
      //     //     "success": true,
      //     //     "message": "questionnaire completed"
      //     // }
      //     //   {
      //     //     "success": false,
      //     //     "message": "questionnaire not completed"
      //     // }
      //   })
      //   .catch(function (error) {
      //     console.log("error", error);
      //     console.log("error", error.status);
      //     console.log("error", error.data);
      //   });

      // $http
      //   .post("/api/answers/customer", {
      //     selected_region: 35,
      //     answerIds: [1, 6, 9, 12, 17, 21, 25, 29, 33, 37],
      //   })
      //   .then(function (response) {
      //     console.log("response", response);
      //   });
    },
  ]);
})();
