/* globals angular */

(function () {
  'use strict';

  angular
    .module('app.virtualtours')
    .controller('virtualToursCtrl', ['$scope', '$filter',
      function ($scope, $filter) {
        $scope.slides = [
          {
            image: '/images/site/virtual-tours/hero-bkg.jpg',
            caption: 'Take A 360° Tour'
          }
        ];

        $scope.subCaption = 'Stunning floorplans including five-level designs, two-story family rooms, ranch-style living, and more!';

        $scope.image = function (tour) {
          if (typeof tour.image !== 'undefined') {
            return tour.image;
          }
          return '/images/site/virtual-tours/' + (tour.name.toLowerCase()) + '.jpg';
        };

        $scope.link = function (name) {
          return '/design/virtual-tours/' + name.toLowerCase().replace(/\s+/g, '-');
        };

        $scope.collections = [{
          name: 'GRAND ESTATES CUSTOM',
          seoName: 'grand-estates-custom-collection',
          subCaption: 'Rich, spacious designs, attentive architectural detail, luxurious and attainable.',
          virtualTours: [{
            url: 'https://my.matterport.com/show/?m=FEPhY4Rr5fS',
            name: 'ALLERTON'
          // image: "https://some-image-path"
          }, {
            url: 'https://my.matterport.com/show/?m=conBx4Ln7ss',
            name: 'CRESTVIEW'
          }, {
            url: 'https://my.matterport.com/show/?m=etpaLkpToA2',
            name: 'ROOKWOOD'
          }, {
            url: 'https://my.matterport.com/show/?m=MWYvNsoxDoT&mls=1',
            name: 'WEDGEFIELD MARKET',
            image: '/images/site/virtual-tours/wedgefield-market.jpg'
          }, {
            url: 'https://my.matterport.com/show/?m=qMSUdaUBkyc',
            name: 'WHEATLAND'
          }]
        }, {
          name: 'MASTERPIECE',
          seoName: 'masterpiece-collection',
          subCaption: 'Beautiful signature elevations. elegant styling and gracious living spaces.',
          virtualTours: [{
            url: 'https://my.matterport.com/show/?m=W88Q5PAUJmw&',
            name: 'ANDOVER'
          }, {
            url: 'https://my.matterport.com/show/?m=5FzfUh4XB22',
            name: 'BAYBERRY'
          }, {
            url: 'https://panoviewer.ml3ds-icon.com/fischer-homes/kentucky/the-beckett',
            name: 'BECKETT'
          }, {
            url: 'https://my.matterport.com/show/?m=vbsMei7HGK7',
            name: 'BRADFORD'
          }, {
            url: 'https://my.matterport.com/show/?m=wotYM5Kcxbm',
            name: 'CLAY'
          }, {
            url: 'https://my.matterport.com/show/?m=zK2MsZJMb4y',
            name: 'EVERETT'
          }, {
            url: 'https://panoviewer.ml3ds-icon.com/fischer-homes/kentucky/the-finley',
            name: 'FINLEY'
          }, {
            url: 'https://my.matterport.com/show/?m=9MWPxw6GQwF',
            name: 'GRAYSON'
          }, {
            url: 'https://my.matterport.com/show/?m=BzqJ78CRpEf',
            name: 'HUXLEY'
          }, {
            url: 'https://my.matterport.com/show/?m=qf41HEnkwLy',
            name: 'LELAND'
          }, {
            url: 'https://my.matterport.com/show/?m=eC5h5pDaUkB',
            name: 'MARGOT'
          }, {
            url: 'https://my.matterport.com/show/?m=6KbS5WM4LXB',
            name: 'MITCHELL',
            image: 'https://d1k0mxjwfbpkpb.cloudfront.net/resources/291228/large/DcLgiI9G_PMoeZZBOz4ZBimGC3138rMVdA.jpg'
          }, {
            url: 'https://my.matterport.com/show/?m=H1rxqkqG6o5',
            name: 'PAXTON'
          }, {
            url: 'https://my.matterport.com/show/?m=KoQ5ATEGjFH',
            name: 'PEARSON'
          }, {
            url: 'https://my.matterport.com/show/?m=AM6fdt69gVR',
            name: 'RHODES'
          }, {
            url: 'https://my.matterport.com/show/?m=eF3sDmARTpH',
            name: 'STANTON'
          }, {
            url: 'https://my.matterport.com/show/?m=PitDQD6HhZX',
            name: 'WINSLOW'
          }]
        }, {
          name: 'DESIGNER',
          seoName: 'designer-collection',
          subCaption: 'The best combination of design, attractive space and affordability.',
          virtualTours: [{
            url: 'https://my.matterport.com/show/?m=8hTaByZZ5LP',
            name: 'AMELIA'
          }, {
            url: 'https://my.matterport.com/show/?m=FdQRRRAMjpo',
            name: 'AVERY'
          }, {
            url: 'https://panoviewer.ml3ds-icon.com/fischer-homes/kentucky/the-birch',
            name: 'BIRCH'
          }, {
            url: 'https://my.matterport.com/show/?m=hsJwSJUbJAU',
            name: 'BLAIR',
            image: 'https://www.fischerhomes.com/cf-imgs/resources/685540/large/PSR-Blair-Model-Interior-Family-Room.jpg'
          }, {
            url: 'https://my.matterport.com/show/?m=K3m7oy751NP',
            name: 'CALVIN',
            image: 'https://d1k0mxjwfbpkpb.cloudfront.net/resources/290481/large/TU0_calvin_family-room_0fh7092_interior.jpg'
          }, {
            url: 'https://panoviewer.ml3ds-icon.com/fischer-homes/kentucky/the-camden',
            name: 'CAMDEN'
          }, {
            url: 'https://my.matterport.com/show/?m=8GWw2nuu5h5',
            name: 'CHARLES'
          }, {
            url: 'https://pano.ml3ds-cloud.com/FischerHomes/36524_DesignerCollection/Delaney/',
            name: 'DELANEY'
          }, {
            url: 'https://my.matterport.com/show/?m=z3rgGqBui2q',
            name: 'EDENTON'
          }, {
            url: 'https://my.matterport.com/show/?m=mbE5UY6vKY7',
            name: 'FOSTER',
            image: 'https://d1k0mxjwfbpkpb.cloudfront.net/resources/143992/large/saw010850000_48-420_pc_foster__dsc3495-HDR.jpg'
          }, {
            url: 'https://my.matterport.com/show/?m=5qtUFecN7di',
            name: 'GRANDIN'
          }, {
            url: 'https://panoviewer.ml3ds-icon.com/fischer-homes/kentucky/the-hazel',
            name: 'HAZEL'
          }, {
            url: 'https://panoviewer.ml3ds-icon.com/fischer-homes/kentucky/the-ivy',
            name: 'IVY'
          }, {
            url: 'https://my.matterport.com/show/?m=hGHBHyPomGe',
            name: 'KEATON'
          }, {
            url: 'https://panoviewer.ml3ds-icon.com/fischer-homes/kentucky/the-linden',
            name: 'LINDEN'
          }, {
            url: 'https://my.matterport.com/show/?m=rzekMgcAtyh',
            name: 'MAGNOLIA'
          }, {
            url: 'https://my.matterport.com/show/?m=GvdbYRfrHnV',
            name: 'MAXWELL',
            image: 'https://d1k0mxjwfbpkpb.cloudfront.net/resources/291239/large/vista-hills_maxwell_25_20200415.jpg'
          }, {
            url: 'https://my.matterport.com/show/?m=bwj8K19wbBk',
            name: 'MILES'
          }, {
            url: 'https://panoviewer.ml3ds-icon.com/fischer-homes/kentucky/the-morgan',
            name: 'MORGAN'
          }, {
            url: 'https://panoviewer.ml3ds-icon.com/fischer-homes/kentucky/the-olive',
            name: 'OLIVE'
          }, {
            url: 'https://my.matterport.com/show/?m=HWWuLNw8Lky',
            name: 'SPRINGFIELD'
          }, {
            url: 'https://panoviewer.ml3ds-icon.com/fischer-homes/kentucky/the-willow',
            name: 'WILLOW'
          }, {
            url: 'https://my.matterport.com/show/?m=MCmKBCEvFtG',
            name: 'WILMINGTON',
            image: 'https://d1k0mxjwfbpkpb.cloudfront.net/resources/274601/large/202%20Cambium%20Court%20-%20Fischer%20Homes%20%286%20of%2047%29%20%281%29%20-%20Copy.jpg'
          }, {
            url: 'https://my.matterport.com/show/?m=vC7YeenRds6',
            name: 'WYATT',
			      image: 'https://d1k0mxjwfbpkpb.cloudfront.net/resources/291983/large/Wyatt%20-%20_DSF8360-HDR.jpg'
          }]
        }, {
          name: 'MAPLE STREET',
          seoName: 'maple-street-collection',
          subCaption: 'Making the dream of homeownership an affordable reality.',
          virtualTours: [{
            url: 'https://my.matterport.com/show/?m=V6unjyHUkZH',
            name: 'BEACON'
          },{
            url: 'https://my.matterport.com/show/?m=AcCTT4vJbVg',
            name: 'BRECKENRIDGE'
          },{
            url: 'https://my.matterport.com/show/?m=yoNwtLsHTG8',
            name: 'CHARLOTTE'
          },{
            url: 'https://my.matterport.com/show/?m=BJBQkq63PNa',
            name: 'CUMBERLAND',
            image: 'https://d1k0mxjwfbpkpb.cloudfront.net/resources/291247/large/crl010510000_m31_cc_cumberland_dsc8315_interior-HDR.jpg'
          }, {
            url: 'https://my.matterport.com/show/?m=iKquT6pKu3T',
            name: 'DAVINCI',
            image: 'https://d1k0mxjwfbpkpb.cloudfront.net/resources/267856/large/davinci-360.png'
          }, {
            url: 'https://my.matterport.com/show/?m=rj7ru7TCUQc',
            name: 'DENALI'
          }, {
            url: 'https://my.matterport.com/show/?m=Di1iVmMXGjS',
            name: 'FAIRFAX'
          }, {
            url: 'https://my.matterport.com/show/?m=hjBSzeuQoH1',
            name: 'GREENBRIAR',
            image: 'https://d1k0mxjwfbpkpb.cloudfront.net/resources/279820/large/villages-at-heritage-creek_greenbriar_family-room_0fh3473_interior..jpg'
          }, {
            url: 'https://my.matterport.com/show/?m=Xiihjv2m67Y',
            name: 'HARPER'
          }, {
            url: 'https://my.matterport.com/show/?m=qYS7taaeNv4',
            name: 'JENSEN',
            image: 'https://d1k0mxjwfbpkpb.cloudfront.net/resources/279759/large/villages-of-decoursey_jensen_kitchen_0fh2562_interior.jpg'
          },{
            url: 'https://my.matterport.com/show/?m=PigLouUbjCo',
            name: 'PRESTON'
          }, {
            url: 'https://my.matterport.com/show/?m=evmMU4Zi6S4',
            name: 'WESLEY'
          }, {
            url: 'https://my.matterport.com/show/?m=voKaSKdruDK',
            name: 'YOSEMITE'
          }]
        }, {
          name: 'PAIRED-PATIO HOMES',
          seoName: 'paired-patio-homes-collection',
          subCaption: 'Privacy of a single family home, but with a low maintenance lifestyle.',
          virtualTours: [{
            url: 'https://my.matterport.com/show/?m=sMEYPWZ1ZWz',
            name: 'HUDSON',
            image: 'https://d1k0mxjwfbpkpb.cloudfront.net/resources/279760/large/discovery-point_hudson_kitchen_0fh3031_interior.jpg'
          }, {
			      url: 'https://my.matterport.com/show/?m=LkVVLa4B7Wa',
            name: 'WEMBLEY'
            }]
        }, {
          name: '30\' MAPLE STREET',
          seoName: 'columbus-maple-street-collection',
          subCaption: 'Making the dream of homeownership an affordable reality.',
          virtualTours: [{
            url: 'https://my.matterport.com/show/?m=LXQEtdrJGVG',
            name: 'APPLETON'
            }]
         }, {
          name: 'MIDTOWN',
          seoName: 'midtown-collection',
          subCaption: 'Multi-level townhome designs with open-concept living spaces and spacious outdoor retreats.',
          virtualTours: [{
            url: 'https://my.matterport.com/show/?m=WUe5h3ct1em',
            name: 'BROOKLINE'
          }, {
			      url: 'https://my.matterport.com/show/?m=MA5Byjydxfk',
            name: 'CLIFTON',
          }, {
			      url: 'https://my.matterport.com/show/?m=ZGunKKZTh3F',
            name: 'TUSTIN'
            }]
          }, {
            name: 'UPTOWN',
            seoName: 'uptown-collection',
            subCaption: 'Single-family home designs with open-concept living spaces.',
            virtualTours: [{
              url: 'https://my.matterport.com/show/?m=Z29cou78RpM&mls=1',
              name: 'IRVING',
              image: 'https://d1k0mxjwfbpkpb.cloudfront.net/resources/292213/large/CPR_11505_Irving_Market_Interior_Kitchen2.jpg'
            }, {
              url: 'https://my.matterport.com/show/?m=8BQmg9A1XT5&mls=1',
              name: 'OAKLEY',
              image: 'https://d1k0mxjwfbpkpb.cloudfront.net/resources/292212/large/CPR_11557_Oakley_Market_Interior_Kitchen.jpg'
              }]
         }, {
          name: 'CONDOMINIUMS',
          seoName: 'condominiums-collection',
          subCaption: 'Flexible, open-concept living with a low-maintenance lifestyle.',
          virtualTours: [{
			      url: 'https://my.matterport.com/show/?m=3mvLz9GgBRy',
            name: 'HAYWARD'
          }, {
            url: 'https://my.matterport.com/show/?m=fCdKsuHEkKF',
            name: 'SAYBROOK'
          }]
        }, {
          name: 'GULF COAST',
          seoName: 'gulf-coast-collection',
          virtualTours: [{
			      url: 'https://my.matterport.com/show/?m=GwrC9WBno9A',
            name: 'TRIPLETAIL II',
            image: '/images/site/virtual-tours/tripletail-ii.jpg'
          }]
        }, {
          name: 'ST. LOUIS TOWNHOMES',
          seoName: 'st-louis-townhomes-collection',
          virtualTours: [{
			      url: 'https://my.matterport.com/show/?m=7CZgWqFtd7x',
            name: 'ASHEVILLE'
          }]
        }, {
          name: 'PAIRED VILLA',
          seoName: 'paired-villa-collection',
          virtualTours: [{
			      url: 'https://tours.livesiteapp.com/?tourId=08c9bb1d-0bc5-4e80-b68d-14aa731163d4',
            name: 'ANFIELD'
          }, {
			      url: 'https://tours.livesiteapp.com/?tourId=01348c59-891c-44c1-885d-f9cd930d1400',
            name: 'LUCAS'
          }]
        }];

        if (typeof seoName !== "undefined") {
          $scope.collections = $filter('filter')($scope.collections, { seoName: seoName }, true);
          $scope.subCaption = $scope.collections[0].subCaption;
        }
      }
    ]);
}());
