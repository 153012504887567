(function () {
  "use strict";
  angular.module("app.forms").directive("fhFormMyFavoritesGetReady", [
    function () {
      return {
        restrict: "E",
        scope: {},
        bindToController: true,
        controllerAs: "vm",
        templateUrl: "forms/fh-form-my-favorites-get-ready",
        link: function () {},
        controller: [
          "FormService",
          "DeviceService",
          "DataService",
          "AnalyticsService",
          "$http",
          function (
            FormService,
            DeviceService,
            DataService,
            AnalyticsService,
            $http
          ) {
            var self = this,
              prefillMode = false,
              prefillData = FormService.prefillForm(
                prefillMode,
                "vendorRequest"
              );
            self.formData = prefillData.formData;
            self.formResult = prefillData.formResult;

            const baseRegions = {
              0: 47,
              1: 1,
              2: 11,
              3: 13,
              4: 22,
              5: 16,
              6: 35,
              7: 54,
              8: 53,
              9: 48,
              10: 52,
              11: 51,
            };

            $http
              .get("/my-favorites/my-profile/edit")
              .then(function (response) {
                self.formData = response.data.user;
                self.formData.selected_regions = {};
                getRegionsByCustomer();
              });
            var getRegionsByCustomer = function () {
              $http.get("/api/regions/customer").then(function (response) {
                const regionsIds = response.data.regions.map(
                  (region) => region.id
                );

                Object.keys(baseRegions).forEach((key) => {
                  if (regionsIds.includes(baseRegions[key])) {
                    self.formData.selected_regions[key] = baseRegions[key];
                  }
                });
              });
            };

            DataService.getData("/api/region", "regions").promise.then(
              function (regions) {
                self.regions = _.filter(regions, function (region) {
                  return region.market_purchasing_manager_email !== "";
                });
              }
            );
            /*
                self.analyticsConfig = {
                  googleAnalytics: {
                    pageViewUrl: '/ajax-goals/vendor-request'
                  }
                };
              
                self.selectRegion = function(regionID) {
                  self.formData.selected_regions = [regionID];
                };
                  */
            self.checkFormValidation = function (form) {
              self.triedSubmit = true;
              if (!form.$valid) {
                return;
              }
              processGetInTouchForm();
            };
            function processGetInTouchForm() {
              self.formResult.showForm = false;
              self.formResult.showLoader = true;
              const payloadRegions = {
                region_ids: Object.values(self.formData.selected_regions),
              };
              prepareFormData();
              console.log("self.formData", self.formData.selected_regions);

              $http({
                method: "POST",
                url: "/my-favorites/my-profile/edit",
                data: $.param(self.formData),
                paramSerializer: "$httpParamSerializerJQLike",
                headers: {
                  "Content-Type": "application/x-www-form-urlencoded",
                }, // set the headers so angular passing info as form data (not request payload)
                // }).then(this.formServiceSubmitResultWash(result));
              })
                .then(function (result) {
                  console.log(result);
                  self.formResult = result;
                  self.formResult.showMessage = result.data.message;
                  self.formResult.message = result.data.message;
                })
                .catch(function (response) {
                  if (
                    response.status == 422 &&
                    Object.keys(response.data.errors).length > 0
                  ) {
                    const errors = Object.keys(response.data.errors)
                      .map((key) => response.data.errors[key])
                      .flat();
                    self.formResult.showMessage = true;
                    self.formResult.message = errors[0];
                    self.formResult.showLoader = false;
                  }
                });

              $http
                .post("api/regions/customer", payloadRegions)
                .then(function (response) {
                  console.log("response", response);
                });
              /*
                  FormService.submit('/my-favorites/my-profile/edit', self.formData)
                    .then(function(result) {
                      self.formResult = result;
                      // AnalyticsService.logTrackingClick(self.analyticsConfig);
                    });
                    */
            }
            function prepareFormData() {
              return;
            }
          },
        ],
      };
    },
  ]);
})();
