(function() {
  'use strict';
  angular
    .module('app.modeldetail')
    .controller('modelDetailCtrl', [
      '$scope',
      'dummyDataService',
      'slickConfigService',
      'DataService',
      'DeviceService',
      'TourService',
      '$timeout',
      '$window',
      function(
        $scope,
        dummyDataService,
        slickConfigService,
        DataService,
        DeviceService,
        TourService,
        $timeout,
        $window) {

        $scope.showBack = true;
        $scope.home = {};
        $scope.floorplans = [];
        var loadedOnce = false;
        $scope.isTourFormHidden = true;
        $scope.tourTimes = {};
        $scope.tourAvailability = {};
        $scope.allowToday = false;
        $scope.toggleTourForm = function () {
            $scope.isTourFormHidden = !$scope.isTourFormHidden;
        };

        $scope.homePromise = DataService.getData($window.location.href, 'model_home')
          .promise
          .then(function(home) {
            $scope.$broadcast('user-selected-model-home', home);
            $scope.home = home;
            $scope.sp1Checks($scope.home.communities[0].region_id);
          })
        ;


        $scope.sp1Checks = function(region){
          if(region === 16){
            $scope.tourAvailability = dummyDataService.daytonTourTimes();
            $scope.allowToday = TourService.availableToday($scope.tourAvailability);
            $scope.tourTimes = TourService.generateWeeklyTourTimes($scope.tourAvailability);
            $scope.formattedTourTimes = TourService.formatTourTimes($scope.tourTimes);
            return true
          }
          return false;
        }

        //resize slick thumbnails based on window size by reinit
        $(window).on('load resize',function(){
          $scope.imagesLoaded = false;

        var thumbCount = 5;
        var stripCount = 2;

        if (DeviceService.check() == "iPad") {
          thumbCount = 3;
          if (DeviceService.orientation() == "portrait") {
            thumbCount = 2;
            stripCount = 1;
          }
        }

        if (DeviceService.check() == "phone") {
          thumbCount = Math.round($(window).width() * 0.01) - 1;
          stripCount = 1;
        }

        $scope.slickStrip = slickConfigService.baseConfig(stripCount);
        slickConfigService.dualSlideshow($scope, thumbCount);
        $timeout(function () {
          if (loadedOnce) {
            $scope.imagesLoaded = true;
          }
        }, 300);
      });

      // inject images into slick slideshow
      // $scope.images = dummyDataService.images();
      $scope.floorplanLevels = dummyDataService.floorplanLevels();

      $scope.homePromise.then(function () {
        $scope.images = $scope.home.assets;

        // TODO: Better to make these into sub-controllers, instead of
        // injecting mystery variables into the local $scope -wo
        $scope.imagesLoaded = true;
        loadedOnce = true;
      });

      $scope.homePromise.then(function () {
        $scope.floorplan = $scope.home.plan;
      });

      $scope.setSlide = function (num) {
        $scope.modalSlide = num;
      };

      $scope.modelHomeAnalyticsConfig = {
        googleAnalytics: {
          pageViewUrl: "/ajax-goals/qsu-model-home-detail",
        },
      };
    },
  ]);
})();
