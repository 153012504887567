/*jslint white: true, nomen: true */
/*global angular, $, communityId */
(function() {
    'use strict';

    angular.module('app.common')
        .directive('hubspotForm', function() {
            return {
                restrict: 'E',
                scope: {
                    formId: '@?',
                    context: '@?',
                    community: '@?',
                    salesAgentId: '@?'
                },
                template: '<div id="{{formContainerId}}"></div>',
                controller: ['$scope', '$http', '$element', function($scope, $http, $element) {
                    $scope.formContainerId = 'hs-form-container-' + Math.floor(Math.random() * 10000);

                    $scope.imageFormIds = [
                        '14bdf957-51f6-47b9-8223-d680c3c17201', 
                        '9a69f966-a929-45dc-b254-270d1a0dda77',
                        'ea225711-1754-4945-a9f4-7c0c8465cd99',
                        '3a812198-40cd-47ba-9f37-925d499a80ec',
                        '3567e714-3f9e-4f12-b56a-65180b425d26',
                        '087cf5b8-37af-46d2-a654-183b22ec89a4',
                        'e60b1d1e-1fa7-43a3-807d-2d0ae1eca6bc',
                        'ec1816c6-dffb-4a32-a987-fe0e7ccae6ec'
                    ];
                    
                    $scope.$onInit = function() {
                        if ($scope.formId && $scope.context === 'random') {
                            $scope.getRandomFormId();
                        } else {
                            $scope.updateImageVisibility($scope.formId);
                            $scope.initializeHubSpotForm($scope.formContainerId, $scope.formId);
                        }
                    };

                    $scope.getRandomFormId = function() {
                        var formIds = $scope.formId.split(',').map(function(id) { return id.trim(); });
                        var randomFormId = formIds[Math.floor(Math.random() * formIds.length)];
                        $scope.updateImageVisibility(randomFormId);
                        $scope.initializeHubSpotForm($scope.formContainerId, randomFormId);
                    };

                    $scope.updateImageVisibility = function (formId) {
                    
                        if (!$scope.salesAgentId) {
                            return;
                        }
                    
                        const salesAgentElement = document.getElementById($scope.salesAgentId);
                    
                        if (!salesAgentElement) {
                            console.warn(`No element found with ID: ${$scope.salesAgentId}`);
                            return;
                        }
                    
                        const salesAgentParentElement = salesAgentElement.parentElement;
                    
                        if ($scope.imageFormIds.includes(formId)) {
                            salesAgentElement.classList.add("show-agent");
                            if (salesAgentParentElement) {
                                salesAgentParentElement.classList.add("show-agent");
                            }
                        } else {
                            salesAgentElement.classList.remove("show-agent");
                            if (salesAgentParentElement) {
                                salesAgentParentElement.classList.remove("show-agent");
                            }
                        }
                    };
                    
                    $scope.initializeHubSpotForm = function(elemId, formId) {
                        if (typeof hbspt !== 'undefined') {
                            hbspt.forms.create({
                                region: "na1",
                                portalId: "8273304",
                                formId: formId,
                                target: `#${elemId}`,
                                onFormReady: function(form) {
                                    const formElement = form.get(0);

                                    if($scope.community){
                                        const communityField = formElement.querySelector(`[name="community_of_interest_1"`);
                                        if (communityField) {
                                            communityField.value = $scope.community;
                                        } else {
                                            console.warn(`HubSpot Forms: Community name '${$scope.community}' provided, but form field not found on Form ID: ${formId}.`);
                                        }
                                    }

                                    form.find('input[type="text"], input[type="email"], textarea').on('input', function() {
                                        $scope.$emit('formDirtyStateChange', {isDirty: true});
                                    });

                                    form.find('select, input[type="checkbox"], input[type="radio"]').on('change', function() {
                                        $scope.$emit('formDirtyStateChange', {isDirty: true});
                                    });

                                    const labels = formElement.getElementsByTagName('label');
                                    Array.from(labels).forEach((label)=>{
                                        if(!label.classList.contains('hs-form-booleancheckbox-display')){
                                            const labelText = label.textContent;
                                            const inputId = label.htmlFor;
                                            const inputElem = formElement.querySelector(`#${inputId}`);
                                            if(inputElem){
                                            inputElem.placeholder = labelText;
                                            label.style.display = 'none';
                                            }
                                        }
                                    });
                                },
                                onFormSubmit: function(){
                                
                                    const emailInputValue = document.getElementById(`email-${formId}`).value;

                                    if (emailInputValue) {
                                        $http({
                                            method: 'POST',
                                            url: '/api/hubspot-crm-data/contact-id',
                                            data: {
                                                email: emailInputValue,
                                            }
                                        }).then(function successCallback(response) {
                                            window.dataLayer = window.dataLayer || [];
                                            window.dataLayer.push({
                                                'event': 'Form Submission',
                                                'user_id': response.data.contactId
                                            });
                                        }, function errorCallback(response) {
                                            console.error('Error:', response);
                                        });
                                    } else {
                                        console.warn('Email address not found.');
                                    }
                                }   
                            });
                        }
                    };
                }],
                link: function(scope, element, attrs) {
                    scope.$onInit();
                }
            };
        });
}());