(function(){
	'Use Strict';
	angular.module('app.discoverthedifference', [])
	.controller('discoverTheDifferenceCtrl',['$scope', 'slickConfigService', 'DeviceService',
	 function($scope, slickConfigService, DeviceService){

	 		$scope.activeSlide = 0;
	 		$scope.isMobile = false;
	 		var slideNum = 3;

	 		if(DeviceService.check() == 'phone'){
	 			$scope.isMobile = true;
	 			slideNum = 1;
	 		}

	 		$scope.timelineCfg = slickConfigService.centerBaseCfg(slideNum, $scope);

			$scope.slides = [
				{
					year: '1980',
					image: '/images/site/discover-the-difference/active/1980.jpg',
					hoverImage: '/images/site/discover-the-difference/inactive/1980.jpg',
					caption: 'Henry and Elaine Fischer founded Fischer Homes in Northern Kentucky with the philosophy of offering the best total new home solution. "Promise only what you can deliver, and deliver what you promise." - Henry Fischer'
				},
				{
					year: '1981',
					image: '/images/site/discover-the-difference/active/1981.jpg',
					hoverImage: '/images/site/discover-the-difference/inactive/1981.jpg',
					caption: 'The first expansion into the Ohio market begins in Anderson Township.'
				},
				{
					year: '1990',
					image: '/images/site/discover-the-difference/active/1990.jpg',
					hoverImage: '/images/site/discover-the-difference/inactive/1990.jpg',
					caption: 'Fischer Homes begins offering lifestyle homes with townhomes and then condominiums the year after. 200 homes are built this year based on the rapid growth since the company was started.'
				},
				{
					year: '2003',
					image: '/images/site/discover-the-difference/active/2003.jpg',
					hoverImage: '/images/site/discover-the-difference/inactive/2003.jpg',
					caption: 'The first St. Jude dream home is constructed beginning an ongoing partnership that has raised over $8 million for St Jude Children\'s Research Hospital.'
				},
				{
					year: '2008',
					image: '/images/site/discover-the-difference/active/2008.jpg',
					hoverImage: '/images/site/discover-the-difference/inactive/2008.jpg',
					caption: 'Expansion to the Columbus, OH market begins. Fischer Homes brings its reputation for quality and design to this attractive central Ohio region.'
				},
				{
					year: '2010',
					image: '/images/site/discover-the-difference/active/2010.jpg',
					hoverImage: '/images/site/discover-the-difference/inactive/2010.jpg',
					caption: 'Fischer Homes enters another market with the expansion into Indianapolis. The company secures attractive land positions and becomes a market leader in just a few short years.'
				},
				{
					year: '2013',
					image: '/images/site/discover-the-difference/active/2013.jpg',
					hoverImage: '/images/site/discover-the-difference/inactive/2013.jpg',
					caption: 'Atlanta is the next market that Fischer Homes enters. This rapidly growing market offers the potential for solid growth in the years to come. Also, Fischer Homes moves its corporate headquarters to a new office in Northern Kentucky to accommodate its growth.'
				},
				{
					year: '2014',
					image: '/images/site/discover-the-difference/active/2014.jpg',
					hoverImage: '/images/site/discover-the-difference/inactive/2014.jpg',
					caption: 'Fischer Homes celebrates the completion of its 20,000th home on December 23rd.'
				},
				{
					year: '2015',
					image: '/images/site/discover-the-difference/active/2015.jpg',
					hoverImage: '/images/site/discover-the-difference/inactive/2015.jpg',
					caption: 'Fischer Homes opens the Nursing Suite at Great American Ballpark in Cincinnati providing new moms with a home away from home at the ballgame - with a nursing area, changing tables, refrigerators, televisions, and restrooms.'
				},
				{
					year: '2016',
					image: '/images/site/discover-the-difference/active/2016.jpg',
					hoverImage: '/images/site/discover-the-difference/inactive/2016.jpg',
					caption: 'The Family Care Center Sponsored by Fischer Homes opens at Kings Island with all of the conveniences families need - a dining area, changing tables, private nursing area, restrooms and an entertainment area for siblings.'
				},
				{
					year: '2018',
					image: '/images/site/discover-the-difference/active/2018.jpg',
					hoverImage: '/images/site/discover-the-difference/inactive/2018.jpg',
					caption: 'Fischer Homes expands into the Louisville Market. The company acquires notable land assets and introduces its award-winning designs to the new market.'
				},
				{
					year: '2019',
					image: 'https://www.fischerhomes.com/cf-imgs/resources/224890/original/2019-active.png',
					hoverImage: 'https://www.fischerhomes.com/cf-imgs/resources/224887/original/2019-inactive.png',
					caption: 'Fischer Homes reaches the monumental achievement of $10 million raised for St. Jude Children\'s Research Hospital.'
				},
				{
					year: '2020',
					image: 'https://www.fischerhomes.com/cf-imgs/resources/224889/original/2020-active.png',
					hoverImage: 'https://www.fischerhomes.com/cf-imgs/resources/224888/original/2020-inactive.png',
					caption: 'Fischer Homes enters its 7th market, Dayton, Ohio, through the acquisition of several new home communities. They quickly become a market leader.'
				},
				{
					year: '2021',
					image: 'https://www.fischerhomes.com/cf-imgs/resources/256486/original/2021-active.png',
					hoverImage: 'https://www.fischerhomes.com/cf-imgs/resources/256487/original/2021-inactive.png',
					caption: 'Fischer Homes expands their footprint to a 5th state by entering St. Louis, Missouri, through acquiring a home builder, solidifying the position as a leader in the area.'
				},
				{
					year: '2022',
					image: '/images/site/discover-the-difference/active/2022.jpg',
					hoverImage: '/images/site/discover-the-difference/inactive/2022.jpg',
					caption: 'Fischer Homes becomes the first homebuilder to build three St. Jude Dream Homes and raise over $3 million for St. Jude Children\'s Research Hospital in a single year.'
				},
				{
					year: '2023',
					image: '/images/site/discover-the-difference/active/2023.jpg',
					hoverImage: '/images/site/discover-the-difference/inactive/2023.jpg',
					caption: 'Fischer Homes officially expands even further south, to the Sunshine State to introduce brand new designs to the Central Panhandle.'
				}
			];
	}]);
}());