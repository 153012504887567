(function () {
    "Use Strict";
    angular.module("app.myfavorites").controller("welcomeCtrl", [
      "$scope",
      "$http",
      "slickConfigService",
      function ($scope, $http,  slickConfigService) {


        $scope.images = [
            {
                // image: "/images/site/my-favorites/my-favorites-hero-bkg.jpg",
                image: "/images/site/floorplan-finder/ff_hero.jpg",
                rightImg: '/images/site/floorplan-finder/ff-flooplan-updated.png',
                alt: "Image 1",
                title: "Fall for the Floorplan",
                subtitle: "Let us be the matchmaker for your dream home!",
                btn: {
                    text: "Start Falling",
                    url: "/floorplan-finder/preferences"
                },
                bg: true,
                color: {
                  txt: 'white',
                  subtitle: 'white',
                  btnTxt: 'black',
                  btnBg: 'white',
                },
            },
            {
                image: "/images/site/my-favorites/slider2.png",
                rightImg: '',
                alt: "Image 2",
                title: "Take a 360° Virtual Tour",
                subtitle: "Experience the magic of your future home from the comfort of your couch!",
                btn: {
                  text: "TAKE A 360° TOUR",
                  url: "/design/virtual-tours"
                },
                bg: false,
                color: {
                  txt: 'rgba(186, 31, 49, 0.88)',
                  subtitle: 'black',
                  btnTxt: 'white',
                  btnBg: 'rgba(186, 31, 49, 0.88)'
                },
            },
            {
                image: "/images/site/my-favorites/slider3.png",
                rightImg: '',
                alt: "Image 3",
                title: "Explore Our Collections",
                subtitle: "Browse our new home collections to find a floorplan that fits your needs",
                btn: {
                  text: "VIEW COLLECTIONS",
                  url: "/design/home-collections"
                },
                bg: false,
                color: {
                  txt: 'white',
                  subtitle: 'white',
                  btnTxt: 'black',
                  btnBg: 'white'
                },
            },
        ]
        
        $scope.slickSettings = slickConfigService.dotsConfig(1);

        $scope.slickPrev = function ($event) {
          var nearestContainer = $event.target.closest(".reg__card-image");
          var nearestSlick = nearestContainer.querySelector(".slick-slider");
  
          if (nearestSlick && nearestSlick.slick) {
            nearestSlick.slick.slickPrev();
          }
        };
        $scope.slickNext = function ($event) {
          var nearestContainer = $event.target.closest(".reg__card-image");
          var nearestSlick = nearestContainer.querySelector(".slick-slider");
  
          if (nearestSlick && nearestSlick.slick) {
            nearestSlick.slick.slickNext();
          }
        };
  
      },
    ]);
  })();
  