(function() {
    "use strict";
  
    angular
  
      .module('modules.authServices')
  
      .service("AuthService", function() {

            var isLoggedIn = false;
  
            return {
                getLoggedInstatus: function() {
                    return isLoggedIn;
                },
                setLoggedInStatus: function(status) {
                    isLoggedIn = status;
                }

            }
  
        });
  
  })();
  