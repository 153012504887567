(function () {
  "Use Strict";
  angular.module("app.saved").controller("savedCtrl", [
    "$scope",
    "$http",
    "$attrs",
    "$element",
    "$timeout",
    "DataService",
    function ($scope, $http, $attrs, $element, $timeout, DataService) {
      $scope.saveabledId = null;
      $scope.items = [];
      $scope.model = "";
      $scope.isSaved = false;

      $scope.init = function () {
        DataService.getData("/api/customer/saves").promise.then(function (saves) {
          $scope.saveabledId = $attrs.id;
          $scope.model = $attrs.model;

          $scope.isSaved = saves[$scope.model].includes(+$scope.saveabledId);

          $element.attr('data-saved',  `${$scope.isSaved}`);
          $element.attr('aria-label', $scope.isSaved ? 'Unsave ' + $scope.model : 'Save ' + $scope.model);
        });
      };

      $scope.init();
    },
  ]);
})();
