(function () {
  "use strict";

  angular.module("app.forms").directive("fhFormVendorRequest", [
    function () {
      return {
        restrict: "E",
        scope: {},
        bindToController: true,
        controllerAs: "vm",
        templateUrl: "forms/fh-form-vendor-request",
        link: function () {},
        controller: [
          "FormService",
          "DeviceService",
          "DataService",
          "AnalyticsService",
          function (FormService, DeviceService, DataService, AnalyticsService) {
            var self = this,
              prefillMode = false,
              prefillData = FormService.prefillForm(
                prefillMode,
                "vendorRequest"
              );

            self.formData = prefillData.formData;
            self.formResult = prefillData.formResult;

            DataService.getData("/api/region", "regions").promise.then(
              function (regions) {
                self.regions = _.filter(regions, function (region) {
                  return region.market_purchasing_manager_email !== "";
                });
              }
            );

            self.analyticsConfig = {
              googleAnalytics: {
                pageViewUrl: "/ajax-goals/vendor-request",
              },
            };

            self.selectRegion = function (regionID) {
              self.formData.selected_regions = [regionID];
            };

            self.checkFormValidation = function (form) {
              self.triedSubmit = true;

              if (!form.$valid) {
                return;
              }

              processGetInTouchForm();
            };

            function processGetInTouchForm() {
              self.formResult.showForm = false;
              self.formResult.showLoader = true;

              prepareFormData();

              FormService.submit("/forms/vendor-request", self.formData).then(
                function (result) {
                  self.formResult = result;

                  AnalyticsService.logTrackingClick(self.analyticsConfig);
                }
              );
            }

            function prepareFormData() {
              return;
            }
          },
        ],
      };
    },
  ]);
})();
