(function () {
  "use strict";

  angular.module("app.common").directive("card", [
    "$window",
    function ($window) {
      return {
        transclude: true,
        scope: {
          templateData: "=templateData",
          listSaved: "=listSaved",
          modelId: "=modelId",
          templateOmit: "@templateOmit",
          overridePhoto: "=photo",
          isLinkOut: "=?",
        },
        link: function (scope, elem, attrs) {
          console.log("templateData:", scope.templateData);

          if (typeof scope.isLinkOut == "undefined") {
            elem.click(function (event) {
              scope.linkOut(event, scope.templateData.url);
            });

            var isCtaLinkEvent = function (event) {
              var str = event.target.className;
              if (!str) {
                return false;
              }
              return str.indexOf("cta-link") >= 0;
            };

            scope.linkOut = function (event, url) {
              if (!isCtaLinkEvent(event)) {
                $window.location = url;
              }
            };
          }
        },
        templateUrl: function (elem, attrs) {
          return attrs.templateUrl;
        },
      };
    },
  ]);
})();
