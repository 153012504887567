(function () {
  "use strict";
  angular.module("app.common").factory("regionStorageFactory", [
    "$localStorage",
    "$sessionStorage",
    "$rootScope",
    "DataService",
    function ($localStorage, $sessionStorage, $rootScope, DataService) {
      //var storageDriver = $localStorage;
      var storageDriver = $sessionStorage;

      var hydrateRegion = function (regionSeo) {
        if (regionSeo === storageDriver.regionSeo) {
          return false;
        }

        storageDriver.regionSeo = regionSeo;

        return DataService.getData(
          "/api/region-revamped/nav/" + regionSeo,
          "region"
        ).promise.then(function (region) {
          storageDriver.chooseRegion = region.name;
          storageDriver.regionSeo = region.seo_name;
          storageDriver.region = region;
          return region;
        });
      };

      return {
        set: function (regionName, regionSeo) {
          var hydrated = hydrateRegion(regionSeo);
          if (hydrated) {
            hydrated.then(function (region) {
              $rootScope.$emit("selectedRegion", region);
            });
          }
        },
        setRegion: function (region) {
          storageDriver.chooseRegion = region.name;
          storageDriver.regionSeo = region.seo_name;
          storageDriver.region = region;
          $rootScope.$emit("selectedRegion", storageDriver.region);
        },
        get: function () {
          return storageDriver.region ? storageDriver.region : false;
        },
        getRegionPhone: function () {
          return storageDriver.region
            ? storageDriver.region.sales_phone
            : false;
        },
        getRegionName: function () {
          return storageDriver.region ? storageDriver.region.name : false;
        },
      };
    },
  ]);
})();
