(function () {
  "Use Strict";
  angular.module("communityCard", []).component("communityCard", {
    templateUrl: "/components/cards/community-card",
    bindings: {
      community: "<",
      communitiesSaved: "<",
    },
    controller: function () {
      var vm = this;

      vm.slickPrev = function ($event) {
        var nearestContainer = $event.target.closest(".reg__card-image");
        var nearestSlick = nearestContainer.querySelector(".slick-slider");

        if (nearestSlick && nearestSlick.slick) {
          nearestSlick.slick.slickPrev();
        }
      };
      vm.slickNext = function ($event) {
        var nearestContainer = $event.target.closest(".reg__card-image");
        var nearestSlick = nearestContainer.querySelector(".slick-slider");

        if (nearestSlick && nearestSlick.slick) {
          nearestSlick.slick.slickNext();
        }
      };
    },
  });
})();
